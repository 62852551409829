export default {
  title: "Keine Umstände",
  title_highlighted: "keine Verpflichtungen",
  description: "Probier’s kostenlos aus und entscheide selbst",
  steps: {
    steps_1: {
      title: "100% risikofrei",
      description:
        "Wir verdienen nur eine kleine Provision, wenn wir Ihnen neue zahlende Kunden vermitteln.",
    },
    steps_2: {
      title: "Keine Vorauszahlungen",
      description: "Es gibt keine Fixkosten, um bei FoodAmigos mitzumachen.",
    },
    steps_3: {
      title: "Jederzeit kündbar",
      description:
        "Keine Verträge - nur AGB. Jederzeit kündbar, ohne Angabe eines Grundes.",
    },
  },
};
