export default {
  title: "Our nr.1",
  title_highlighted: "solution",
  description: "How much unlocked potential do you have?",
  block: {
    block1: {
      title: "Returning customers",
      description: "Customers who visit 1x month",
    },
    block2: {
      returning_customers: {
        title: "Returning Customers: ",
        customers: "{{count}}",
        description: "/Month",
      },
      revenue: {
        title: "Additional Profit: ",
        revenue: "{{revenue}}",
        description: "/Month",
      },
    },
  },
  button: {
    title: "Start Free Trial",
  },
};
