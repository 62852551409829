export default {
  title: "Loslegen ist ganz ",
  title_highlighted: "einfach",
  description: "Unverbindlich anfragen - es dauert nur 3 Minuten.",
  steps: {
    steps_1: {
      title: "Kreiere einen Account",
    },
    steps_2: {
      title: "Fügen deine Unternehmens - und persönlichen Infos hinzu.",
    },
    steps_3: {
      title:
        "Du wirst von uns eine Rückmeldung innerhalb von 24 Stunden erhalten.",
    },
  },
  button: {
    title: "Kostenlos Registrieren",
  },
};
