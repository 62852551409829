import React from 'react';
import { ConfigProvider } from 'antd';

export default function AntConfigProvider({
  children,
}) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#69CE8C',
        },
        components: {
          Modal: {
            zIndexPopupBase: 1000,
          },
          DatePicker: {
            zIndexPopupBase: 5000,
          },
          Slider: {
            trackBg: '#69CE8C',
            trackHoverBg: '#69CE8C',
          }
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}
