import { useLayoutEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import tw from "twin.macro";

const PhoneNumberInput = ({ value, placeholder, country, disabled, inputContainerStyle, id, onChange, onChangeCountry }) => {

    const [elementId, setElementId] = useState(0);

    const renderRemoveIcon = () => {
        return (
            <div
                className={'bg-brand-text-grey rounded-full flex justify-center items-center w-5 h-5 text-[#F2F2F2]'}
                onClick={() => onChange('')}
            >
                <CloseOutlined className={'h-2 w-2'} />
            </div>
        );
    };

    const renderCheckmarkIcon = () => {
        return (
            <div
                className={'bg-brand-primary rounded-full flex justify-center items-center w-5 h-5 text-white'}
                id={`checkmark-${id}`}
            >
                <CheckOutlined className={'h-2 w-2'} />
            </div>
        );
    };

    const onPaste = () => {
        setTimeout(() => setElementId(elementId + 1));
    };

    function changeValue(value) {
        return onChange && onChange(value ?? '');
    }

    function isValid() {
        return isValidPhoneNumber(value, country);
    }

    function formatValue() {
        return parsePhoneNumber(value)?.formatInternational();
    }

    useLayoutEffect(() => {
        const phoneNumberInput = document.getElementById('phone-number-input');

        if (phoneNumberInput) {
            phoneNumberInput.classList.add('mini');
        }
    });

    const renderInput = () => {
        function renderActionIcons() {
            if (value?.length === 0) {
                return null;
            }
            if (isValid()) {
                return renderCheckmarkIcon();
            }
            return renderRemoveIcon();
        }

        const renderPlaceholder = () => {
            return value?.length === 0 ? (
                <PlaceHolderContainer>
                    <PlaceHolder>123 4567890</PlaceHolder>
                </PlaceHolderContainer>
            ) : null;
        };

        return (
            <Wrapper
                style={{ borderWidth: 1 }}
            >
                <FormContainer onPaste={onPaste}>
                    {renderPlaceholder()}
                    <PhoneInput
                        id="phone-number-input"
                        key={elementId}
                        country={country ?? 'DE'}
                        defaultCountry={country ?? 'DE'}
                        placeholder={placeholder}
                        value={formatValue()}
                        onChange={changeValue}
                        onCountryChange={onChangeCountry}
                        international={true}
                        countryCallingCodeEditable={false}
                        onBlur={onPaste}
                        style={{
                            width: '100%',
                            position: 'relative',
                        }}
                    />
                </FormContainer>
                {!disabled && <div className="mx-2">{renderActionIcons()}</div>}
            </Wrapper>
        );
    };

    return <Container>{renderInput()}</Container>;
};

export default PhoneNumberInput;

const Container = tw.div`flex flex-row justify-between items-center`;

const Wrapper = tw.div`placeholder:text-[#CBCBCB] rounded-lg flex flex-row px-[16px] py-[12px] items-center w-full border border-[#CBCBCB]`;

const FormContainer = tw.form`w-full relative`;

const PlaceHolderContainer = tw.form`absolute inset-0 pl-[70px] z-0 mb-0 flex items-center`;

const PlaceHolder = tw.p`text-[#CBCBCB] -mb-1 pb-0`;