export default {
  title: "So ",
  title_highlighted: "funktioniert’s...",
  description:
    "Erzähle deinen Amigos nicht nur von tollen Spots, teilen deinen Code und erhalte 🌮🍕🍩!",
  steps: {
    steps_1: {
      title: "Empfehle Amigos",
      description:
        "Teilen deinen persönlichen Restaurant Empfehlungslink oder Code.",
    },
    steps_2: {
      title: "Amigos verwenden deinen Code",
      description:
        "Sie erhalten bis zu 50% Rabatt auf ihren Einkauf beim jeweiligen Restaurant.",
    },
    steps_3: {
      title: "Erhalte Belohnungen",
      description:
        "Bekomme Rabatte und gratis 🌮🍕🥗 beim jeweiligen Restaurant.",
    },
  },
};
