import axios from "axios";

class BackendApiClient {
  client;

  baseURL = this.getApiBaseUrl();

  constructor() {
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  /**
   * Performs a request on the backend api
   *
   * @param configuration
   */
  async requestAsync(configuration) {
    return this.client
      .request(configuration)
      .then((response) => {
        return { response, success: true };
      })
      .catch((error) => {
        return {
          response: error,
          success: false,
          validationErrors: error.response?.data?.errors,
        };
      });
  }

  getApiBaseUrl() {
    return process.env.REACT_APP_API_BASE_URL;
  }

  getDomain() {
    return process.env.REACT_APP_DOMAIN;
  }

  getDashboardDomain() {
    return process.env.REACT_APP_DOMAIN_DASHBOARD;
  }
}

export default new BackendApiClient();
