export default {
  title: "No Effort",
  title_highlighted: "No Commitments",
  description: "Try it out for free and decide for yourself",
  steps: {
    steps_1: {
      title: "Risk-free",
      description:
        "We earn only a small commission when we bring you new paying customers.",
    },
    steps_2: {
      title: "No upfront payment",
      description: "There are no fixed costs, to join FoodAmigos.",
    },
    steps_3: {
      title: "Cancel anytime",
      description:
        "No contracts - only terms and conditions. You can cancel at any time, without giving a reason.",
    },
  },
};
