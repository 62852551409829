const COOKIE_STATUS_KEY = "Cookie_Permission_Status";
class CookieManager {
  getCookiePermissionStatus() {
    return localStorage.getItem(COOKIE_STATUS_KEY);
  }

  updateCookiePermissionStatus(status) {
    return localStorage.setItem(COOKIE_STATUS_KEY, status);
  }
}

export default new CookieManager();
