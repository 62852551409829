export default {
  description: "0% commission, fixed monthly price, no long-term contracts",
  sales: "Sales",
  commission: "Lieferando & Co Commission",
  average_order: "Average Order Value",
  service_fee_lieferando: "Service Fee per Order for Lieferando & Co",
  pickup: 'Pickup',
  delivery: 'Delivery',
  total_savings: "Total Savings",
  tabs: {
    own_drivers: "Own Drivers",
    foodamigos_drivers: "Foodamigos Drivers",
  },
  chart: {
    fees: "Fees",
  },
  duration: {
    monthly: "Monthly",
    yearly: "Yearly",
  },
};
