import React, { useEffect } from "react";
import { isAndroid } from "react-device-detect";

export default () => {
  useEffect(() => {
    if (isAndroid) {
      redirectToAndroidApp();
    }
  }, []);

  function redirectToAndroidApp() {
    const url =
      "intent://#Intent;scheme=com.joinamigo.app;package=com.joinamigo.app;end";
    window.location.href = url;
  }

  return null;
};
