export default {
  headers: {
    header_1: {
      title: "Allgemein",
      tabs: {
        tab_1: "Home",
        tab_2: "Restaurants",
      },
    },
    header_2: {
      title: "Gastronomen",
      tabs: {
        tab_1: "Anmelden",
        tab_2: "Registrieren",
      },
    },
    header_3: {
      title: "Recht",
      tabs: {
        tab_1: "Datenschutz",
        tab_2: "AGBs",
        tab_3: "Impressum",
        tab_4: "Cookie-Richtlinie",
      },
    },
    header_4: {
      title: "FoodAmigos",
      describtion: "Die Nr. 1 Lösung zur Umsatzsteigerung für lokale Restaurants.",
    },
  },
};
