export default {
  title: "Fragen?",
  title_highlighted: "Sende uns gerne eine Nachricht",
  email: {
    title: "Email Adresse",
    placeholder: "Deine Email Adresse",
  },
  name: {
    title: "Vollständiger Name",
    placeholder: "Vollständiger Name",
  },
  restaurant_name: {
    title: "Restaurant Name",
    placeholder: "Restaurant Name",
  },
  message: {
    title: "Nachricht",
    placeholder: "Nachricht...",
  },
  button: {
    title: "Senden",
  },
  success_messages: {
    success1: "Nachricht wurde gesendet.",
  },
  error_messages: {
    error1: "Senden der Nachricht fehlgeschlagen. Bitte versuche es erneut.",
    error2: "Fehlende Informationen",
  },
};
