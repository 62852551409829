import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import tw from "twin.macro";

const IncrementalInput = (props) => {
  const { incrementRate, value, min, max, onChange, addonAfter, fixed = 2 } = props;

  function toNum(value) {
    return isNaN(value) ? 0 : Number(value);
  }

  function onChangeRate(type) {
    let newValue;
    const _value = toNum(value);

    if (type === 'increase') {
      newValue = _value + (incrementRate || 1);
    } else {
      newValue = _value - (incrementRate || 1);
    }

    newValue = Number((Math.round(newValue * 100) / 100).toFixed(fixed));

    if (newValue < min || newValue > max) {
      return;
    }

    onChange(
      newValue.toString()
    );
  }

  function _onChange(e) {
    const _value = e.target.value.replace(/,/g, '.');;
    onChange(_value);
  }

  return (
    <Container style={{ height: 35, borderColor: '#e4e4e4', flexShrink: 0 }}>
      <Button icon={<MinusOutlined />} onClick={onChangeRate} style={{ border: 0, height: '100%' }} />
      <InputContainer>
        <Input defaultValue={value} value={value} onChange={_onChange} style={{ textAlign: 'center', width: 60, paddingTop: 1, paddingBottom: 1 }} />
      </InputContainer>
      <Button icon={<PlusOutlined />} onClick={() => onChangeRate('increase')} style={{ border: 0, height: '100%' }} />
      <AddOnContainer style={{ borderColor: '#e4e4e4' }}>
        {addonAfter}
      </AddOnContainer>
    </Container>
  );
};

export default IncrementalInput;

const Container = tw.div`flex gap-2 justify-center items-center border rounded-md w-fit overflow-hidden`;

const InputContainer = tw.div`w-fit flex items-center justify-center`;

const AddOnContainer = tw.div`py-2 px-3 justify-center items-center flex bg-[#fafafa] border-l h-full`;
