import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import "services/localization/Localization";
import "bootstrap/dist/css/bootstrap.min.css";
import RestaurantPage from "./pages/RestaurantPage.js";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService.js";
import Impressum from "./pages/Impressum.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ContactUs from "pages/ContactUs.js";
import PayPalConfirmation from "pages/PayPalConfirmation.js";
import CookiePolicy from "./pages/CookiePolicy";
import RoiCalculator from "pages/roi-calculator/index.js";
import AntConfigProvider from "providers/AntConfigProvider.jsx";
import SeoProvider from "providers/SeoProvider.jsx";

export default function App() {
  return (
    <SeoProvider>
      <AntConfigProvider>
        <Router>
          <Switch>
            <Route path="/paypal-callback" name="PayPal Callback">
              <PayPalConfirmation />
            </Route>
            <Route path="/privacy-policy" name="Privacy Policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/cookie-policy" name="Cookie Policy">
              <CookiePolicy />
            </Route>
            <Route path="/terms" name="Terms and Conditions">
              <TermsOfService />
            </Route>
            <Route path="/impressum" name="Impressum">
              <Impressum />
            </Route>
            <Route path="/contact" name="Contact">
              <ContactUs />
            </Route>
            <Route path="/roirechner" name="ROI Calculator">
              <RoiCalculator />
            </Route>
            <Route path="/" name="Home">
              <RestaurantPage />
            </Route>
          </Switch>
        </Router>
      </AntConfigProvider>
    </SeoProvider>
  );
}
