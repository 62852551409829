export default {
  description:
    "Wenn Sie auf „Alle Cookies akzeptieren“ klicken, stimmen Sie der Speicherung von Cookies auf Ihrem Gerät zu, um die Websitenavigation zu verbessern, die Websitenutzung zu analysieren und unsere Marketingbemühungen zu unterstützen.",
  cookie_policy: 'Cookie-Richtlinie',
  button: {
    button_1: {
      title: "Alle Cookies akzeptieren",
    },
    button_2: {
      title: "Ablehnen",
    },
  },
};
