export default {
  title: "Bist du ein ",
  highlighted_title: "Restaurantbesitzer?",
  description:
    "Registriere deinen Betrieb kostenlos, um FoodAmigos besser kennenzulernen.",
  subText: "Lasse deine Kunden das Marketing für dich übernehmen",
  button: {
    title: "Mehr erfahren",
  },
};
