import { createSlice } from "@reduxjs/toolkit";
import { CookiePermissionStatus } from "services/exports/Constants";

export default createSlice({
  name: "appStatus",
  initialState: {
    cookiePermissionStatus: CookiePermissionStatus.NOT_DETERMENED,
  },
  reducers: {
    updateCookieStatus: (state, action) => {
      try {
        state.cookiePermissionStatus = action.payload;
      } catch (error) {}
    },
  },
});

export const appStatusProps = (state) => state;
