export const AVAILABLE_LANGUAGES = [
  {
    language: "de",
    languageName: "German",
  },
  {
    language: "en",
    languageName: "English",
  },
];

export const CookiePermissionStatus = {
  REJECTED: "rejected",
  ACEEPTED: "accepted",
};

export const POLICIES = {
  PRIVACY_POLICIES:
    "https://docs.google.com/document/d/1xyoXzB3LIrRHruJbdKXMlH3lFzsM_vn89uDU3fhh0yw/edit?usp=sharing",
  TERMS_AND_CONDITIONS:
    "https://docs.google.com/document/d/1q8UrbZMBn89cJeikVgqD-mZ6jQpWXgrYYyfv6R5vkGc/edit?usp=sharing",
  COOKIE_POLICY:
    "https://docs.google.com/document/d/1pnxJaQgyiaKzpHS4ahgtLF9Zlusdv3Hg2bDbmqkPNWg/edit#heading=h.xsled2qv7pd",
};
