import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import RestaurantIntro from "components/hero/RestaurantIntro.js";
import Footer from "components/footers/Footer.js";
import RestaurantBenefits from "components/features/RestaurantBenefits";
import Header from "../components/headers/dark.js";
import EnableCookies from "components/cta/EnableCookies.js";

export default () => {
  return (
    <>
      <Header />
      <Wrapper>
        <AnimationRevealPage>
          <RestaurantIntro />
          <RestaurantBenefits />
          {/* <RestaurantSalesCalculator /> */}
          {/* <RestaurantServices /> */}
          {/* <RestaurantGettingStartedGuide /> */}
          {/* <GetInTouch /> */}
          <Footer />
        </AnimationRevealPage>
      </Wrapper>
      <EnableCookies />
    </>
  );
};

export const Wrapper = tw.div`pt-24`;