export default {
  title: "Unsere Nr.1",
  title_highlighted: "Lösung",
  description: "Wie viel ungenutztes Potenzial hat dein Lokal?",
  block: {
    block1: {
      title: "Wiederkehrende Kunden",
      description:
        "Anzahl Kunden, die mindestens 1x/ Monat zu Gast bei dir sind",
    },
    block2: {
      returning_customers: {
        title: "Wiederkehrende Neukunden: ",
        customers: "{{count}}",
        description: "/Monat",
      },
      revenue: {
        title: "Extra Profit: ",
        revenue: "{{revenue}}",
        description: "/Monat",
      },
    },
  },
  button: {
    title: "Kostenlos Registrieren",
  },
};
