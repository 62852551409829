import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/dark.js";
import Footer from "components/footers/Footer.js";
import RestaurantIntro from "components/hero/RestaurantIntro";

export const Wrapper = tw.div`pt-16`;

export default () => {
  return (
    <>
      <Header />
      <AnimationRevealPage>
        <Wrapper>
          <RestaurantIntro />
        </Wrapper>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
