export default {
  title: "Any questions? ",
  title_highlighted: "Message us",
  email: {
    title: "Email",
    placeholder: "Your Email Address",
  },
  name: {
    title: "Full name",
    placeholder: "Full name",
  },
  restaurant_name: {
    title: "Restaurant name",
    placeholder: "Restaurant name",
  },
  message: {
    title: "Message",
    placeholder: "Message...",
  },
  button: {
    title: "Send",
  },
  success_messages: {
    success1: "Successfully sent message.",
  },
  error_messages: {
    error1: "Failed to send message. Please try again",
    error2: "Missing Information",
  },
};
