import React, { useState, useMemo } from "react";
import tw from "twin.macro";
import { CookiePermissionStatus, POLICIES } from "services/exports/Constants";
import { useTranslation } from "react-i18next";
import CookieManager from "services/cookies/CookieManager";
import CookieConsent from "react-cookie-consent";

const EnableCookies = () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Cta:EnableCookies";
  const [status, setStatus] = useState(
    CookieManager.getCookiePermissionStatus()
  );

  const description = t(TRANSLATION_PATH + ".description");
  const cookie_policy = t(TRANSLATION_PATH + ".cookie_policy");
  const button1 = t(TRANSLATION_PATH + ":button:button_1.title");
  const button2 = t(TRANSLATION_PATH + ":button:button_2.title");

  function accept() {
    const newStatus = CookiePermissionStatus.ACEEPTED;
    CookieManager.updateCookiePermissionStatus(newStatus);
    setStatus(newStatus);
  }

  function reject() {
    const newStatus = CookiePermissionStatus.REJECTED;
    CookieManager.updateCookiePermissionStatus(newStatus);
    setStatus(newStatus);
  }

  const renderEnableCookies = useMemo(() => {
    return (
      <Container>
        <CookieConsent
          visible={"show"}
          location="bottom"
          buttonText={""}
          cookieName="myAwesomeCookieName2"
          style={{ background: "#69CE8C" }}
          disableButtonStyles
        >
          <Container>
            <Row>
              <TextContainer>
                <Description>
                    {description}
                    {' '}
                    <Link href="/cookie-policy" target="_blank" rel="noopener noreferrer">{t(TRANSLATION_PATH + ".cookie_policy")}</Link>
                </Description>
              </TextContainer>
              <LinksContainer>
                <PrimaryLink onClick={accept}>{button1}</PrimaryLink>
                <SecondaryLink onClick={reject}>{button2}</SecondaryLink>
              </LinksContainer>
            </Row>
          </Container>
        </CookieConsent>
      </Container>
    );
  }, [status]);

  function renderCookieReuqest() {
    if (!status) {
      return renderEnableCookies;
    }
    return null;
  }

  return renderCookieReuqest();
};

export default EnableCookies;

const Container = tw.div`bg-primary-default px-4 py-2`;
const Row = tw.div`flex  lg:justify-between justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const TextContainer = tw.div`flex items-center justify-center lg:mb-0 mb-4`;
const Description = tw.p`text-white text-xs xl:text-xs pb-0 mb-0`;
const CookiePolicy = tw.a`text-white text-xs xl:text-xs underline inline-block pb-0 mb-0 hover:text-white`;

const LinksContainer = tw.div`flex-none justify-center items-center flex-row lg:mt-0 mb-2 mt-6`;
const Link = tw.a`text-sm font-medium text-white no-underline border-b-2 border-transparent hocus:text-gray-default hocus:border-white pb-1 transition duration-300 lg:text-left text-center`;

const PrimaryLink = tw.button`rounded px-4 py-3 mx-2 text-xs sm:text-sm sm:px-4 font-bold border-2 border-white bg-white text-primary-default hocus:shadow-lg`;
const SecondaryLink = tw.button`rounded px-4 py-3 ml-2 text-xs sm:text-sm sm:px-4 font-bold bg-transparent border-2 border-white text-white hocus:shadow-lg`;
