import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

const SeoProvider = ({ children }) => {
    const noIndex = useMemo(
        () =>
            ['/privacy-policy', '/cookie-policy', '/terms', '/impressum'].some((screen) =>
                window.location.href.includes(screen),
            ) && <meta name="robots" content="noindex, nofollow" />,
        [window.location.href],
    );
    return (
        <>
            <Helmet>
                {noIndex}
            </Helmet>
            {children}
        </>
    )
}

export default SeoProvider;