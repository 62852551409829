import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AppStatusSlice from "./slices/AppStatusSlice";

const persistConfig = {
  key: "root",
  storage,
};

export const reducersCombined = combineReducers({
  appStatus: AppStatusSlice.reducer,
});

const reducerPersisted = persistReducer(persistConfig, reducersCombined);

const rootReducer = (state, actions) => {
  if (actions.type === "RESET_STORE") {
    state = undefined;
  }
  return reducerPersisted(state, actions);
};

function configure() {
  let store = configureStore({
    reducer: rootReducer,
  });

  return store;
}

export const store = configure();
