export default {
  title: "made easy for restaurants",
  title_highlighted: "Online growth",
  description: "Thousands of restaurant owners use Foodamigos to succeed online. Grow your sales with a great online experience – just like the big franchises.",
  no_contracts: 'No contracts. No long-term commitment. Nothing to lose.',
  rating: 'Top rated across 1000s of restaurants',
  form: {
    title: 'Free demo - We will call you back within 24 hours',
    fields: {
      name: 'Full name',
      restaurant: {
        name: 'Restaurant name',
        address: 'Restaurant Street + Nr',
        zip_code: 'Zip code',
        city: 'City',
      },
      email: 'Email Address',
      phone_number: 'Phone number',
    },
    button: {
      demo: "Get a free Demo",
    },
  },
  toast: {
    success: 'We have received your request and will contact you shortly.',
    error: 'Failed to send demo request. Please try again.'
  },
};
