import ConsumerGettingStartedGuide from "./ConsumerGettingStartedGuide";
import RestaurantBenefitsBlock from "./RestaurantBenefitsBlock";
import RestaurantSalesCalculator from "./RestaurantSalesCalculator";
import RestaurantServices from "./RestaurantServices";
import RestaurantGettingStartedGuide from "./RestaurantGettingStartedGuide";

export default {
  ConsumerGettingStartedGuide,
  RestaurantBenefitsBlock,
  RestaurantSalesCalculator,
  RestaurantServices,
  RestaurantGettingStartedGuide,
};
