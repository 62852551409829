import React from 'react';

const StarIcon = ({
    width, height, style
}) => {
    return (
        <svg width={width ?? 20} height={height ?? 20} style={style} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.6144 16C9.85275 15.8561 10.1512 15.8561 10.3895 16L14.6602 18.5776C15.2282 18.9204 15.9289 18.4112 15.7781 17.7651L14.6448 12.9069C14.5815 12.6358 14.6737 12.352 14.8841 12.1697L18.6584 8.90001C19.1599 8.4656 18.8918 7.64191 18.2307 7.58582L13.2626 7.16433C12.9854 7.14081 12.744 6.96581 12.6355 6.70968L10.6925 2.12537C10.4341 1.5156 9.56985 1.5156 9.31141 2.12537L7.36844 6.70968C7.25989 6.96581 7.01849 7.14081 6.7413 7.16433L1.77317 7.58582C1.11211 7.64191 0.84405 8.4656 1.3455 8.90001L5.1198 12.1697C5.33022 12.352 5.42236 12.6358 5.35911 12.9069L4.22576 17.7651C4.07504 18.4112 4.77572 18.9204 5.3437 18.5776L9.6144 16Z" fill="#F2C94C" />
        </svg>

    );
};

export default StarIcon;
