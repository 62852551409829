export default {
  title: "Getting started is ",
  title_highlighted: "easy",
  description: "Become our partner - it takes only 3 min.",
  steps: {
    steps_1: {
      title: "Create an account",
    },
    steps_2: {
      title: "Add your company and personal information.",
    },
    steps_3: {
      title: "You will receive a reply from us within 24 hours",
    },
  },
  button: {
    title: "Start Free Trial",
  },
};
