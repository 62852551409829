export default {
  headers: {
    header_1: "Home",
    header_2: "Gastronomie",
    header_3: "Contact",
    header_4: "FAQ",
  },
  button: {
    button_1: {
      title: "Login",
    },
    button_2: {
      title: "Register",
    },
  },
};
