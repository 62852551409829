export default {
  headers: {
    header_1: {
      title: "Main",
      tabs: {
        tab_1: "Home",
        tab_2: "Restaurants",
      },
    },
    header_2: {
      title: "Gastronomie",
      tabs: {
        tab_1: "Login",
        tab_2: "Register",
      },
    },
    header_3: {
      title: "Legal",
      tabs: {
        tab_1: "Privacy Policy",
        tab_2: "Terms and Conditions",
        tab_3: "Impressum",
        tab_4: "Cookie Policy",
      },
    },
    header_4: {
      title: "FoodAmigos",
      describtion: "The Nr. 1 tech solution to maximize revenue for local restaurants.",
    },
  },
};
