export default {
  headers: {
    header_1: "Home",
    header_2: "Gastronomen",
    header_3: "Kontakt",
    header_4: "FAQ",
  },
  button: {
    button_1: {
      title: "Anmelden",
    },
    button_2: {
      title: "Registrieren",
    },
  },
};
