import BackendApiClient from "./BackendApiClient";

class ContactManager {
  post = (data) => BackendApiClient.requestAsync({
    method: 'POST',
    url: '/website/contact',
    data,
  });
}

export default new ContactManager();
