export default {
  title: "Everything You Need to Grow",
  items: {
    item_1: {
      title: "Custom Website",
      description:
        "Create a professional restaurant website in minutes with our easy to use website builder.",
    },
    item_2: {
      title: "Delivery Integration",
      description:
        "Connect with multiple delivery platforms through a single dashboard.",
    },
    item_3: {
      title: "Analytics Dashboard",
      description:
        "Track sales, popular items, and customer behavior with detailed analytics.",
    },
    item_4: {
      title: "Mobile Ordering",
      description:
        "Let customers order directly through your mobile-optimized website.",
    },
    item_5: {
      title: "Order Management",
      description:
        "Manage all your orders from different platforms in one place.",
    },
    item_6: {
      title: "Quick Setup",
      description:
        "Get your restaurant online in just 15 minutes with our guided setup process.",
    },
  },
};
