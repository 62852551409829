export default {
  title: "Alles, was Sie zum Wachsen brauchen",
  items: {
    item_1: {
      title: "Individuelle Website",
      description:
        "Erstellen Sie in wenigen Minuten eine professionelle Restaurant-Website mit unserem benutzerfreundlichen Website-Builder.",
    },
    item_2: {
      title: "Lieferintegration",
      description:
        "Verbinden Sie sich über ein einziges Dashboard mit mehreren Lieferplattformen.",
    },
    item_3: {
      title: "Analyse-Dashboard",
      description:
        "Verfolgen Sie Verkäufe, beliebte Artikel und das Kundenverhalten mit detaillierten Analysen.",
    },
    item_4: {
      title: "Mobile Bestellungen",
      description:
        "Ermöglichen Sie Kunden, direkt über Ihre mobiloptimierte Website zu bestellen.",
    },
    item_5: {
      title: "Bestellverwaltung",
      description:
        "Verwalten Sie alle Ihre Bestellungen von verschiedenen Plattformen an einem Ort.",
    },
    item_6: {
      title: "Schnelle Einrichtung",
      description:
        "Bringen Sie Ihr Restaurant in nur 15 Minuten mit unserem geführten Einrichtungsprozess online.",
    },
  },
};
