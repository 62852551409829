import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { createGlobalStyle } from "styled-components";
import Footer from "components/footers/Footer.js";

const Style = createGlobalStyle`
    ul.lst-kix_ieh6bbjxy2lo-5 {
        list-style-type: none
    }

    ul.lst-kix_ieh6bbjxy2lo-6 {
        list-style-type: none
    }

    .lst-kix_ieh6bbjxy2lo-7 > li:before {
        content: "\\0025cb   "
    }

    .lst-kix_ieh6bbjxy2lo-8 > li:before {
        content: "\\0025a0   "
    }

    ul.lst-kix_ieh6bbjxy2lo-7 {
        list-style-type: none
    }

    ol.lst-kix_list_7-0 {
        list-style-type: none
    }

    .lst-kix_list_14-1 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) " "
    }

    .lst-kix_list_14-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-3, lower-latin) ") "
    }

    ul.lst-kix_ieh6bbjxy2lo-8 {
        list-style-type: none
    }

    .lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1
    }

    ol.lst-kix_list_15-6 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-1.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-1 0
    }

    ol.lst-kix_list_15-7 {
        list-style-type: none
    }

    ol.lst-kix_list_15-8 {
        list-style-type: none
    }

    ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0
    }

    .lst-kix_list_14-0 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) ". "
    }

    .lst-kix_list_14-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-4, lower-roman) ") "
    }

    ol.lst-kix_list_15-2 {
        list-style-type: none
    }

    .lst-kix_ieh6bbjxy2lo-4 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_list_15-3 {
        list-style-type: none
    }

    ol.lst-kix_list_15-4 {
        list-style-type: none
    }

    .lst-kix_list_6-1 > li {
        counter-increment: lst-ctn-kix_list_6-1
    }

    .lst-kix_list_14-5 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) "." counter(lst-ctn-kix_list_14-3, lower-latin) "." counter(lst-ctn-kix_list_14-4, lower-roman) "." counter(lst-ctn-kix_list_14-5, decimal) " "
    }

    .lst-kix_list_14-7 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) "." counter(lst-ctn-kix_list_14-3, lower-latin) "." counter(lst-ctn-kix_list_14-4, lower-roman) "." counter(lst-ctn-kix_list_14-5, decimal) "." counter(lst-ctn-kix_list_14-6, decimal) "." counter(lst-ctn-kix_list_14-7, decimal) " "
    }

    ul.lst-kix_ieh6bbjxy2lo-0 {
        list-style-type: none
    }

    ol.lst-kix_list_15-5 {
        list-style-type: none
    }

    ul.lst-kix_ieh6bbjxy2lo-1 {
        list-style-type: none
    }

    ul.lst-kix_ieh6bbjxy2lo-2 {
        list-style-type: none
    }

    .lst-kix_ieh6bbjxy2lo-5 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_ieh6bbjxy2lo-6 > li:before {
        content: "\\0025cf   "
    }

    ul.lst-kix_ieh6bbjxy2lo-3 {
        list-style-type: none
    }

    ol.lst-kix_list_15-0 {
        list-style-type: none
    }

    ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0
    }

    .lst-kix_list_14-6 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) "." counter(lst-ctn-kix_list_14-3, lower-latin) "." counter(lst-ctn-kix_list_14-4, lower-roman) "." counter(lst-ctn-kix_list_14-5, decimal) "." counter(lst-ctn-kix_list_14-6, decimal) " "
    }

    ul.lst-kix_ieh6bbjxy2lo-4 {
        list-style-type: none
    }

    ol.lst-kix_list_15-1 {
        list-style-type: none
    }

    .lst-kix_list_13-0 > li {
        counter-increment: lst-ctn-kix_list_13-0
    }

    ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0
    }

    .lst-kix_list_17-0 > li {
        counter-increment: lst-ctn-kix_list_17-0
    }

    .lst-kix_list_5-0 > li {
        counter-increment: lst-ctn-kix_list_5-0
    }

    .lst-kix_list_9-0 > li {
        counter-increment: lst-ctn-kix_list_9-0
    }

    ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0
    }

    ol.lst-kix_list_7-5 {
        list-style-type: none
    }

    ol.lst-kix_list_7-6 {
        list-style-type: none
    }

    ol.lst-kix_list_7-7 {
        list-style-type: none
    }

    ol.lst-kix_list_7-8 {
        list-style-type: none
    }

    ol.lst-kix_list_7-1 {
        list-style-type: none
    }

    ol.lst-kix_list_7-2 {
        list-style-type: none
    }

    ol.lst-kix_list_11-8.start {
        counter-reset: lst-ctn-kix_list_11-8 0
    }

    ol.lst-kix_list_7-3 {
        list-style-type: none
    }

    ol.lst-kix_list_7-4 {
        list-style-type: none
    }

    .lst-kix_list_14-2 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) " "
    }

    ul.lst-kix_6heel87l2wmr-0 {
        list-style-type: none
    }

    ul.lst-kix_6heel87l2wmr-1 {
        list-style-type: none
    }

    ul.lst-kix_6heel87l2wmr-2 {
        list-style-type: none
    }

    ul.lst-kix_6heel87l2wmr-3 {
        list-style-type: none
    }

    ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0
    }

    ol.lst-kix_list_12-0.start {
        counter-reset: lst-ctn-kix_list_12-0 26
    }

    .lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3
    }

    .lst-kix_m6920b9r5jdo-3 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-3
    }

    ol.lst-kix_list_17-1.start {
        counter-reset: lst-ctn-kix_list_17-1 0
    }

    ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0
    }

    .lst-kix_list_14-8 > li:before {
        content: "" counter(lst-ctn-kix_list_14-0, decimal) "." counter(lst-ctn-kix_list_14-1, decimal) "." counter(lst-ctn-kix_list_14-2, decimal) "." counter(lst-ctn-kix_list_14-3, lower-latin) "." counter(lst-ctn-kix_list_14-4, lower-roman) "." counter(lst-ctn-kix_list_14-5, decimal) "." counter(lst-ctn-kix_list_14-6, decimal) "." counter(lst-ctn-kix_list_14-7, decimal) "." counter(lst-ctn-kix_list_14-8, decimal) " "
    }

    ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0
    }

    .lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2
    }

    ol.lst-kix_m6920b9r5jdo-6.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-6 0
    }

    ol.lst-kix_list_15-5.start {
        counter-reset: lst-ctn-kix_list_15-5 0
    }

    .lst-kix_list_7-2 > li {
        counter-increment: lst-ctn-kix_list_7-2
    }

    .lst-kix_list_5-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-0, lower-latin) ") "
    }

    ol.lst-kix_list_6-0 {
        list-style-type: none
    }

    ol.lst-kix_list_6-1 {
        list-style-type: none
    }

    .lst-kix_list_14-8 > li {
        counter-increment: lst-ctn-kix_list_14-8
    }

    .lst-kix_list_5-4 > li {
        counter-increment: lst-ctn-kix_list_5-4
    }

    .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4
    }

    ol.lst-kix_m6920b9r5jdo-0 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0
    }

    ol.lst-kix_m6920b9r5jdo-1 {
        list-style-type: none
    }

    .lst-kix_list_5-3 > li:before {
        content: "" counter(lst-ctn-kix_list_5-3, lower-latin) ") "
    }

    ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0
    }

    ol.lst-kix_m6920b9r5jdo-2 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-3 {
        list-style-type: none
    }

    .lst-kix_list_5-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-2, lower-roman) ") "
    }

    ol.lst-kix_m6920b9r5jdo-4 {
        list-style-type: none
    }

    .lst-kix_list_8-3 > li {
        counter-increment: lst-ctn-kix_list_8-3
    }

    ol.lst-kix_m6920b9r5jdo-5 {
        list-style-type: none
    }

    .lst-kix_list_5-1 > li:before {
        content: "" counter(lst-ctn-kix_list_5-1, decimal) ". "
    }

    ol.lst-kix_m6920b9r5jdo-6 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-7 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-8 {
        list-style-type: none
    }

    .lst-kix_list_5-7 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-7, lower-latin) ") "
    }

    .lst-kix_list_5-6 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-6, lower-roman) ") "
    }

    .lst-kix_list_5-8 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-8, lower-roman) ") "
    }

    .lst-kix_list_9-4 > li {
        counter-increment: lst-ctn-kix_list_9-4
    }

    ol.lst-kix_list_6-6 {
        list-style-type: none
    }

    ol.lst-kix_list_6-7 {
        list-style-type: none
    }

    .lst-kix_list_5-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-4, decimal) ") "
    }

    ol.lst-kix_list_6-8 {
        list-style-type: none
    }

    .lst-kix_list_5-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-5, lower-latin) ") "
    }

    ol.lst-kix_list_6-2 {
        list-style-type: none
    }

    ol.lst-kix_list_6-3 {
        list-style-type: none
    }

    ol.lst-kix_list_6-4 {
        list-style-type: none
    }

    ol.lst-kix_list_6-5 {
        list-style-type: none
    }

    ol.lst-kix_list_14-1.start {
        counter-reset: lst-ctn-kix_list_14-1 0
    }

    .lst-kix_list_6-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-1, decimal) ") "
    }

    .lst-kix_list_6-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-3, decimal) ") "
    }

    .lst-kix_list_6-5 > li {
        counter-increment: lst-ctn-kix_list_6-5
    }

    .lst-kix_list_6-8 > li {
        counter-increment: lst-ctn-kix_list_6-8
    }

    .lst-kix_list_6-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-0, decimal) ") "
    }

    .lst-kix_list_6-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-4, decimal) ") "
    }

    ol.lst-kix_list_14-8.start {
        counter-reset: lst-ctn-kix_list_14-8 0
    }

    ul.lst-kix_list_16-2 {
        list-style-type: none
    }

    ul.lst-kix_list_16-1 {
        list-style-type: none
    }

    ul.lst-kix_list_16-0 {
        list-style-type: none
    }

    .lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6
    }

    .lst-kix_list_6-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-2, decimal) ") "
    }

    ol.lst-kix_list_15-0.start {
        counter-reset: lst-ctn-kix_list_15-0 0
    }

    ul.lst-kix_list_16-8 {
        list-style-type: none
    }

    ul.lst-kix_list_16-7 {
        list-style-type: none
    }

    ul.lst-kix_list_16-6 {
        list-style-type: none
    }

    .lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5
    }

    .lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8
    }

    ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0
    }

    ul.lst-kix_list_16-5 {
        list-style-type: none
    }

    ul.lst-kix_list_16-4 {
        list-style-type: none
    }

    .lst-kix_list_6-8 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-8, lower-roman) ") "
    }

    ul.lst-kix_list_16-3 {
        list-style-type: none
    }

    .lst-kix_list_6-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-5, lower-latin) ") "
    }

    .lst-kix_list_6-7 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-7, lower-latin) ") "
    }

    .lst-kix_list_6-6 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-6, lower-roman) ") "
    }

    ol.lst-kix_list_17-8 {
        list-style-type: none
    }

    .lst-kix_list_7-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-4, lower-latin) ") "
    }

    .lst-kix_list_7-6 > li:before {
        content: " "
    }

    ol.lst-kix_list_17-4 {
        list-style-type: none
    }

    ol.lst-kix_list_17-5 {
        list-style-type: none
    }

    ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0
    }

    .lst-kix_list_15-5 > li {
        counter-increment: lst-ctn-kix_list_15-5
    }

    ol.lst-kix_list_17-6 {
        list-style-type: none
    }

    ol.lst-kix_list_17-7 {
        list-style-type: none
    }

    ol.lst-kix_list_17-0 {
        list-style-type: none
    }

    ol.lst-kix_list_17-1 {
        list-style-type: none
    }

    ol.lst-kix_list_17-2 {
        list-style-type: none
    }

    .lst-kix_list_7-2 > li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) "." counter(lst-ctn-kix_list_7-1, decimal) "." counter(lst-ctn-kix_list_7-2, decimal) " "
    }

    ol.lst-kix_list_17-3 {
        list-style-type: none
    }

    .lst-kix_list_7-6 > li {
        counter-increment: lst-ctn-kix_list_7-6
    }

    .lst-kix_list_8-6 > li {
        counter-increment: lst-ctn-kix_list_8-6
    }

    ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0
    }

    ol.lst-kix_list_9-7 {
        list-style-type: none
    }

    ol.lst-kix_list_9-8 {
        list-style-type: none
    }

    .lst-kix_list_13-7 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) "." counter(lst-ctn-kix_list_13-3, lower-latin) "." counter(lst-ctn-kix_list_13-4, lower-roman) "." counter(lst-ctn-kix_list_13-5, decimal) "." counter(lst-ctn-kix_list_13-6, decimal) "." counter(lst-ctn-kix_list_13-7, decimal) " "
    }

    ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0
    }

    ol.lst-kix_list_9-3 {
        list-style-type: none
    }

    ol.lst-kix_list_9-4 {
        list-style-type: none
    }

    .lst-kix_list_5-7 > li {
        counter-increment: lst-ctn-kix_list_5-7
    }

    ol.lst-kix_list_9-5 {
        list-style-type: none
    }

    .lst-kix_m6920b9r5jdo-4 > li:before {
        content: "(" counter(lst-ctn-kix_m6920b9r5jdo-4, lower-roman) ") "
    }

    .lst-kix_list_7-8 > li:before {
        content: " "
    }

    ol.lst-kix_list_9-6 {
        list-style-type: none
    }

    .lst-kix_list_15-6 > li {
        counter-increment: lst-ctn-kix_list_15-6
    }

    .lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7
    }

    ol.lst-kix_list_9-0 {
        list-style-type: none
    }

    ol.lst-kix_list_9-1 {
        list-style-type: none
    }

    ol.lst-kix_list_9-2 {
        list-style-type: none
    }

    ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0
    }

    .lst-kix_list_15-5 > li:before {
        content: "" counter(lst-ctn-kix_list_15-5, lower-roman) ". "
    }

    .lst-kix_list_9-8 > li {
        counter-increment: lst-ctn-kix_list_9-8
    }

    .lst-kix_list_13-4 > li {
        counter-increment: lst-ctn-kix_list_13-4
    }

    .lst-kix_list_4-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-1, lower-roman) ") "
    }

    .lst-kix_m6920b9r5jdo-2 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-1, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-2, decimal) " "
    }

    .lst-kix_list_15-7 > li:before {
        content: "" counter(lst-ctn-kix_list_15-7, lower-latin) ". "
    }

    .lst-kix_list_17-7 > li {
        counter-increment: lst-ctn-kix_list_17-7
    }

    ul.lst-kix_list_19-7 {
        list-style-type: none
    }

    ul.lst-kix_list_19-6 {
        list-style-type: none
    }

    .lst-kix_list_4-3 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-roman) "." counter(lst-ctn-kix_list_4-1, lower-roman) "." counter(lst-ctn-kix_list_4-2, lower-roman) "." counter(lst-ctn-kix_list_4-3, decimal) ". "
    }

    .lst-kix_list_4-5 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-roman) "." counter(lst-ctn-kix_list_4-1, lower-roman) "." counter(lst-ctn-kix_list_4-2, lower-roman) "." counter(lst-ctn-kix_list_4-3, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) "." counter(lst-ctn-kix_list_4-5, decimal) ". "
    }

    .lst-kix_m6920b9r5jdo-0 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) ". "
    }

    ul.lst-kix_list_19-5 {
        list-style-type: none
    }

    ul.lst-kix_list_19-4 {
        list-style-type: none
    }

    ul.lst-kix_list_19-3 {
        list-style-type: none
    }

    .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8
    }

    ul.lst-kix_list_19-2 {
        list-style-type: none
    }

    ul.lst-kix_list_19-1 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-3.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-3 0
    }

    ul.lst-kix_list_19-0 {
        list-style-type: none
    }

    .lst-kix_list_15-1 > li:before {
        content: "" counter(lst-ctn-kix_list_15-1, lower-latin) ". "
    }

    ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0
    }

    .lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5
    }

    ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0
    }

    .lst-kix_list_15-3 > li:before {
        content: "" counter(lst-ctn-kix_list_15-3, decimal) ". "
    }

    ul.lst-kix_list_19-8 {
        list-style-type: none
    }

    ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0
    }

    .lst-kix_list_6-4 > li {
        counter-increment: lst-ctn-kix_list_6-4
    }

    ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0
    }

    .lst-kix_list_9-3 > li {
        counter-increment: lst-ctn-kix_list_9-3
    }

    .lst-kix_list_11-2 > li {
        counter-increment: lst-ctn-kix_list_11-2
    }

    ol.lst-kix_list_15-2.start {
        counter-reset: lst-ctn-kix_list_15-2 0
    }

    ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0
    }

    ol.lst-kix_list_8-8 {
        list-style-type: none
    }

    .lst-kix_list_12-3 > li:before {
        content: " "
    }

    ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0
    }

    ol.lst-kix_list_8-4 {
        list-style-type: none
    }

    .lst-kix_list_12-1 > li:before {
        content: " "
    }

    ul.lst-kix_6heel87l2wmr-8 {
        list-style-type: none
    }

    ol.lst-kix_list_8-5 {
        list-style-type: none
    }

    ol.lst-kix_list_8-6 {
        list-style-type: none
    }

    ol.lst-kix_list_8-7 {
        list-style-type: none
    }

    ol.lst-kix_list_8-0 {
        list-style-type: none
    }

    ul.lst-kix_6heel87l2wmr-4 {
        list-style-type: none
    }

    ol.lst-kix_list_8-1 {
        list-style-type: none
    }

    ul.lst-kix_6heel87l2wmr-5 {
        list-style-type: none
    }

    ol.lst-kix_list_8-2 {
        list-style-type: none
    }

    .lst-kix_list_13-3 > li {
        counter-increment: lst-ctn-kix_list_13-3
    }

    ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0
    }

    ul.lst-kix_6heel87l2wmr-6 {
        list-style-type: none
    }

    ol.lst-kix_list_8-3 {
        list-style-type: none
    }

    ul.lst-kix_6heel87l2wmr-7 {
        list-style-type: none
    }

    .lst-kix_m6920b9r5jdo-6 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-1, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-2, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-3, lower-latin) "." counter(lst-ctn-kix_m6920b9r5jdo-4, lower-roman) "." counter(lst-ctn-kix_m6920b9r5jdo-5, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-6, decimal) " "
    }

    .lst-kix_m6920b9r5jdo-8 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-1, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-2, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-3, lower-latin) "." counter(lst-ctn-kix_m6920b9r5jdo-4, lower-roman) "." counter(lst-ctn-kix_m6920b9r5jdo-5, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-6, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-7, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-8, decimal) " "
    }

    ol.lst-kix_list_15-3.start {
        counter-reset: lst-ctn-kix_list_15-3 0
    }

    .lst-kix_list_14-1 > li {
        counter-increment: lst-ctn-kix_list_14-1
    }

    .lst-kix_list_13-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-3, lower-latin) ") "
    }

    ul.lst-kix_list_18-0 {
        list-style-type: none
    }

    ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0
    }

    .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3
    }

    .lst-kix_list_13-5 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) "." counter(lst-ctn-kix_list_13-3, lower-latin) "." counter(lst-ctn-kix_list_13-4, lower-roman) "." counter(lst-ctn-kix_list_13-5, decimal) " "
    }

    .lst-kix_list_12-5 > li:before {
        content: " "
    }

    ul.lst-kix_list_18-8 {
        list-style-type: none
    }

    ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0
    }

    .lst-kix_ieh6bbjxy2lo-0 > li:before {
        content: "\\0025cf   "
    }

    ul.lst-kix_list_18-7 {
        list-style-type: none
    }

    ul.lst-kix_list_18-6 {
        list-style-type: none
    }

    ul.lst-kix_list_18-5 {
        list-style-type: none
    }

    .lst-kix_list_12-7 > li:before {
        content: " "
    }

    ul.lst-kix_list_18-4 {
        list-style-type: none
    }

    ul.lst-kix_list_18-3 {
        list-style-type: none
    }

    ul.lst-kix_list_18-2 {
        list-style-type: none
    }

    ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0
    }

    ul.lst-kix_list_18-1 {
        list-style-type: none
    }

    .lst-kix_ieh6bbjxy2lo-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2
    }

    .lst-kix_list_13-1 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) " "
    }

    ol.lst-kix_list_3-1 {
        list-style-type: none
    }

    ol.lst-kix_list_3-2 {
        list-style-type: none
    }

    ol.lst-kix_list_3-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0
    }

    .lst-kix_list_5-1 > li {
        counter-increment: lst-ctn-kix_list_5-1
    }

    ol.lst-kix_list_3-4 {
        list-style-type: none
    }

    ol.lst-kix_list_3-0 {
        list-style-type: none
    }

    ol.lst-kix_list_11-6 {
        list-style-type: none
    }

    ol.lst-kix_list_11-7 {
        list-style-type: none
    }

    ol.lst-kix_list_11-8 {
        list-style-type: none
    }

    .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1
    }

    .lst-kix_list_7-1 > li {
        counter-increment: lst-ctn-kix_list_7-1
    }

    ol.lst-kix_list_11-2 {
        list-style-type: none
    }

    ol.lst-kix_list_11-3 {
        list-style-type: none
    }

    ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0
    }

    .lst-kix_list_3-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-0, upper-latin) ") "
    }

    ol.lst-kix_list_11-4 {
        list-style-type: none
    }

    ol.lst-kix_list_11-5 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-4.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-4 0
    }

    ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0
    }

    ol.lst-kix_list_11-0 {
        list-style-type: none
    }

    ol.lst-kix_list_11-1 {
        list-style-type: none
    }

    .lst-kix_list_21-8 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0
    }

    .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0
    }

    .lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0
    }

    .lst-kix_list_3-4 > li:before {
        content: "" counter(lst-ctn-kix_list_3-4, decimal) ") "
    }

    .lst-kix_list_3-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-3, lower-roman) ") "
    }

    ol.lst-kix_list_3-5 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6 {
        list-style-type: none
    }

    .lst-kix_list_8-0 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) ". "
    }

    ol.lst-kix_list_3-7 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8 {
        list-style-type: none
    }

    .lst-kix_list_8-7 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) "." counter(lst-ctn-kix_list_8-3, lower-latin) "." counter(lst-ctn-kix_list_8-4, lower-roman) "." counter(lst-ctn-kix_list_8-5, decimal) "." counter(lst-ctn-kix_list_8-6, decimal) "." counter(lst-ctn-kix_list_8-7, decimal) " "
    }

    .lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
    }

    .lst-kix_list_21-0 > li:before {
        content: "-  "
    }

    .lst-kix_list_13-1 > li {
        counter-increment: lst-ctn-kix_list_13-1
    }

    .lst-kix_list_21-1 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_15-8.start {
        counter-reset: lst-ctn-kix_list_15-8 0
    }

    .lst-kix_list_8-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-3, lower-latin) ") "
    }

    .lst-kix_6heel87l2wmr-3 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
    }

    .lst-kix_list_8-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-4, lower-roman) ") "
    }

    .lst-kix_iwk9uuq7es81-7 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0
    }

    .lst-kix_iwk9uuq7es81-8 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_list_17-1 > li {
        counter-increment: lst-ctn-kix_list_17-1
    }

    .lst-kix_list_11-1 > li:before {
        content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". "
    }

    .lst-kix_list_21-5 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_6heel87l2wmr-2 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_21-4 > li:before {
        content: "o  "
    }

    .lst-kix_list_11-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-0, decimal) ") "
    }

    ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0
    }

    .lst-kix_list_8-8 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) "." counter(lst-ctn-kix_list_8-3, lower-latin) "." counter(lst-ctn-kix_list_8-4, lower-roman) "." counter(lst-ctn-kix_list_8-5, decimal) "." counter(lst-ctn-kix_list_8-6, decimal) "." counter(lst-ctn-kix_list_8-7, decimal) "." counter(lst-ctn-kix_list_8-8, decimal) " "
    }

    ol.lst-kix_list_2-2 {
        list-style-type: none
    }

    .lst-kix_list_16-8 > li:before {
        content: "\\0025aa   "
    }

    ol.lst-kix_list_2-3 {
        list-style-type: none
    }

    .lst-kix_8h3ngux134lm-3 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_2-4 {
        list-style-type: none
    }

    .lst-kix_list_16-7 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0
    }

    ol.lst-kix_list_2-5 {
        list-style-type: none
    }

    .lst-kix_8h3ngux134lm-2 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_8h3ngux134lm-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_17-8 > li {
        counter-increment: lst-ctn-kix_list_17-8
    }

    ol.lst-kix_list_2-0 {
        list-style-type: none
    }

    ol.lst-kix_list_2-1 {
        list-style-type: none
    }

    .lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-roman) "." counter(lst-ctn-kix_list_4-1, lower-roman) "." counter(lst-ctn-kix_list_4-2, lower-roman) "." counter(lst-ctn-kix_list_4-3, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) "." counter(lst-ctn-kix_list_4-5, decimal) "." counter(lst-ctn-kix_list_4-6, decimal) "." counter(lst-ctn-kix_list_4-7, decimal) "." counter(lst-ctn-kix_list_4-8, decimal) ". "
    }

    .lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-roman) "." counter(lst-ctn-kix_list_4-1, lower-roman) "." counter(lst-ctn-kix_list_4-2, lower-roman) "." counter(lst-ctn-kix_list_4-3, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) "." counter(lst-ctn-kix_list_4-5, decimal) "." counter(lst-ctn-kix_list_4-6, decimal) "." counter(lst-ctn-kix_list_4-7, decimal) ". "
    }

    .lst-kix_list_14-2 > li {
        counter-increment: lst-ctn-kix_list_14-2
    }

    .lst-kix_list_17-0 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) ". "
    }

    .lst-kix_6heel87l2wmr-7 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_16-0 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_6heel87l2wmr-6 > li:before {
        content: "\\0025aa   "
    }

    ol.lst-kix_list_12-0 {
        list-style-type: none
    }

    .lst-kix_iwk9uuq7es81-4 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0
    }

    .lst-kix_list_8-4 > li {
        counter-increment: lst-ctn-kix_list_8-4
    }

    .lst-kix_list_16-4 > li:before {
        content: "o  "
    }

    .lst-kix_8h3ngux134lm-7 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0
    }

    .lst-kix_iwk9uuq7es81-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_16-3 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_2-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7 {
        list-style-type: none
    }

    ol.lst-kix_list_2-8 {
        list-style-type: none
    }

    .lst-kix_list_11-3 > li {
        counter-increment: lst-ctn-kix_list_11-3
    }

    ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0
    }

    .lst-kix_iwk9uuq7es81-0 > li:before {
        content: "\\0025cf   "
    }

    ul.lst-kix_list_12-6 {
        list-style-type: none
    }

    ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0
    }

    ul.lst-kix_list_12-5 {
        list-style-type: none
    }

    .lst-kix_list_17-7 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) "." counter(lst-ctn-kix_list_17-3, lower-latin) "." counter(lst-ctn-kix_list_17-4, lower-roman) "." counter(lst-ctn-kix_list_17-5, decimal) "." counter(lst-ctn-kix_list_17-6, decimal) "." counter(lst-ctn-kix_list_17-7, decimal) " "
    }

    ul.lst-kix_list_12-4 {
        list-style-type: none
    }

    ul.lst-kix_list_12-3 {
        list-style-type: none
    }

    ul.lst-kix_list_12-2 {
        list-style-type: none
    }

    ul.lst-kix_list_12-1 {
        list-style-type: none
    }

    .lst-kix_list_17-8 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) "." counter(lst-ctn-kix_list_17-3, lower-latin) "." counter(lst-ctn-kix_list_17-4, lower-roman) "." counter(lst-ctn-kix_list_17-5, decimal) "." counter(lst-ctn-kix_list_17-6, decimal) "." counter(lst-ctn-kix_list_17-7, decimal) "." counter(lst-ctn-kix_list_17-8, decimal) " "
    }

    .lst-kix_list_17-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_17-3, lower-latin) ") "
    }

    .lst-kix_list_17-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_17-4, lower-roman) ") "
    }

    ul.lst-kix_list_12-8 {
        list-style-type: none
    }

    ul.lst-kix_list_12-7 {
        list-style-type: none
    }

    ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0
    }

    .lst-kix_m6920b9r5jdo-2 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-2
    }

    .lst-kix_list_7-0 > li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) " "
    }

    .lst-kix_list_13-8 > li {
        counter-increment: lst-ctn-kix_list_13-8
    }

    .lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2
    }

    ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0
    }

    ol.lst-kix_list_5-0 {
        list-style-type: none
    }

    .lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7
    }

    ol.lst-kix_list_5-1 {
        list-style-type: none
    }

    ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0
    }

    ol.lst-kix_list_5-2 {
        list-style-type: none
    }

    ol.lst-kix_list_13-8 {
        list-style-type: none
    }

    .lst-kix_list_2-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-4, lower-latin) ") "
    }

    .lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
    }

    ol.lst-kix_list_13-4 {
        list-style-type: none
    }

    ol.lst-kix_list_13-5 {
        list-style-type: none
    }

    ol.lst-kix_list_13-6 {
        list-style-type: none
    }

    ol.lst-kix_list_13-7 {
        list-style-type: none
    }

    ol.lst-kix_list_13-0 {
        list-style-type: none
    }

    ol.lst-kix_list_13-1 {
        list-style-type: none
    }

    .lst-kix_list_6-6 > li {
        counter-increment: lst-ctn-kix_list_6-6
    }

    ol.lst-kix_list_13-2 {
        list-style-type: none
    }

    ol.lst-kix_list_15-7.start {
        counter-reset: lst-ctn-kix_list_15-7 0
    }

    .lst-kix_list_7-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-3, lower-roman) ") "
    }

    ol.lst-kix_list_13-3 {
        list-style-type: none
    }

    .lst-kix_list_10-0 > li:before {
        content: "" counter(lst-ctn-kix_list_10-0, decimal) ". "
    }

    .lst-kix_list_9-7 > li {
        counter-increment: lst-ctn-kix_list_9-7
    }

    .lst-kix_list_13-6 > li {
        counter-increment: lst-ctn-kix_list_13-6
    }

    .lst-kix_list_13-8 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) "." counter(lst-ctn-kix_list_13-3, lower-latin) "." counter(lst-ctn-kix_list_13-4, lower-roman) "." counter(lst-ctn-kix_list_13-5, decimal) "." counter(lst-ctn-kix_list_13-6, decimal) "." counter(lst-ctn-kix_list_13-7, decimal) "." counter(lst-ctn-kix_list_13-8, decimal) " "
    }

    ol.lst-kix_list_14-6.start {
        counter-reset: lst-ctn-kix_list_14-6 0
    }

    .lst-kix_list_18-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_18-7 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_5-7 {
        list-style-type: none
    }

    ol.lst-kix_list_5-8 {
        list-style-type: none
    }

    ol.lst-kix_list_5-3 {
        list-style-type: none
    }

    .lst-kix_list_8-7 > li {
        counter-increment: lst-ctn-kix_list_8-7
    }

    .lst-kix_m6920b9r5jdo-5 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-1, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-2, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-3, lower-latin) "." counter(lst-ctn-kix_m6920b9r5jdo-4, lower-roman) "." counter(lst-ctn-kix_m6920b9r5jdo-5, decimal) " "
    }

    ol.lst-kix_list_5-4 {
        list-style-type: none
    }

    ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0
    }

    ol.lst-kix_list_5-5 {
        list-style-type: none
    }

    ol.lst-kix_list_5-6 {
        list-style-type: none
    }

    .lst-kix_list_7-7 > li:before {
        content: " "
    }

    ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0
    }

    ol.lst-kix_m6920b9r5jdo-0.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-0 0
    }

    .lst-kix_list_15-4 > li:before {
        content: "" counter(lst-ctn-kix_list_15-4, lower-latin) ". "
    }

    .lst-kix_list_9-5 > li {
        counter-increment: lst-ctn-kix_list_9-5
    }

    .lst-kix_list_5-8 > li {
        counter-increment: lst-ctn-kix_list_5-8
    }

    .lst-kix_list_10-4 > li:before {
        content: " "
    }

    .lst-kix_list_10-8 > li:before {
        content: " "
    }

    .lst-kix_list_4-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-0, lower-roman) ") "
    }

    .lst-kix_m6920b9r5jdo-0 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-0
    }

    .lst-kix_list_15-0 > li:before {
        content: "" counter(lst-ctn-kix_list_15-0, decimal) ". "
    }

    .lst-kix_list_15-8 > li:before {
        content: "" counter(lst-ctn-kix_list_15-8, lower-roman) ". "
    }

    .lst-kix_m6920b9r5jdo-1 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-1, decimal) " "
    }

    ol.lst-kix_list_14-3.start {
        counter-reset: lst-ctn-kix_list_14-3 0
    }

    .lst-kix_list_15-7 > li {
        counter-increment: lst-ctn-kix_list_15-7
    }

    .lst-kix_list_4-4 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-roman) "." counter(lst-ctn-kix_list_4-1, lower-roman) "." counter(lst-ctn-kix_list_4-2, lower-roman) "." counter(lst-ctn-kix_list_4-3, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) ". "
    }

    ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0
    }

    .lst-kix_list_9-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-3, lower-roman) ") "
    }

    ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0
    }

    ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0
    }

    ol.lst-kix_list_4-0 {
        list-style-type: none
    }

    ol.lst-kix_list_4-1 {
        list-style-type: none
    }

    ol.lst-kix_list_4-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-3 {
        list-style-type: none
    }

    ol.lst-kix_list_14-7 {
        list-style-type: none
    }

    ol.lst-kix_list_14-4.start {
        counter-reset: lst-ctn-kix_list_14-4 0
    }

    ol.lst-kix_list_14-8 {
        list-style-type: none
    }

    .lst-kix_list_9-7 > li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". "
    }

    .lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4
    }

    ol.lst-kix_list_14-3 {
        list-style-type: none
    }

    ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0
    }

    ol.lst-kix_list_14-4 {
        list-style-type: none
    }

    ol.lst-kix_list_14-5 {
        list-style-type: none
    }

    ol.lst-kix_list_14-6 {
        list-style-type: none
    }

    .lst-kix_list_11-4 > li:before {
        content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". "
    }

    .lst-kix_list_15-2 > li {
        counter-increment: lst-ctn-kix_list_15-2
    }

    ol.lst-kix_list_14-0 {
        list-style-type: none
    }

    .lst-kix_list_12-4 > li:before {
        content: " "
    }

    ol.lst-kix_list_14-1 {
        list-style-type: none
    }

    ol.lst-kix_list_14-2 {
        list-style-type: none
    }

    .lst-kix_list_5-3 > li {
        counter-increment: lst-ctn-kix_list_5-3
    }

    .lst-kix_list_20-5 > li:before {
        content: "\\0025aa   "
    }

    ol.lst-kix_list_4-8 {
        list-style-type: none
    }

    .lst-kix_list_7-4 > li {
        counter-increment: lst-ctn-kix_list_7-4
    }

    .lst-kix_list_1-0 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "
    }

    .lst-kix_list_20-1 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_4-4 {
        list-style-type: none
    }

    .lst-kix_list_11-8 > li:before {
        content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". "
    }

    ol.lst-kix_list_4-5 {
        list-style-type: none
    }

    ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0
    }

    .lst-kix_m6920b9r5jdo-7 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-7
    }

    ol.lst-kix_list_4-6 {
        list-style-type: none
    }

    .lst-kix_list_12-0 > li:before {
        content: "" counter(lst-ctn-kix_list_12-0, lower-latin) ") "
    }

    .lst-kix_list_17-3 > li {
        counter-increment: lst-ctn-kix_list_17-3
    }

    ol.lst-kix_list_4-7 {
        list-style-type: none
    }

    ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0
    }

    .lst-kix_list_1-4 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) " "
    }

    .lst-kix_list_13-0 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) ". "
    }

    ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0
    }

    .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6
    }

    ol.lst-kix_list_13-0.start {
        counter-reset: lst-ctn-kix_list_13-0 0
    }

    .lst-kix_list_14-4 > li {
        counter-increment: lst-ctn-kix_list_14-4
    }

    .lst-kix_list_13-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-4, lower-roman) ") "
    }

    .lst-kix_list_2-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-0, lower-latin) ") "
    }

    ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0
    }

    ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0
    }

    .lst-kix_list_11-5 > li {
        counter-increment: lst-ctn-kix_list_11-5
    }

    .lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5
    }

    ol.lst-kix_list_14-5.start {
        counter-reset: lst-ctn-kix_list_14-5 0
    }

    ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0
    }

    .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) "." counter(lst-ctn-kix_list_1-7, decimal) "." counter(lst-ctn-kix_list_1-8, decimal) " "
    }

    .lst-kix_ieh6bbjxy2lo-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_12-8 > li:before {
        content: " "
    }

    .lst-kix_list_8-2 > li {
        counter-increment: lst-ctn-kix_list_8-2
    }

    .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1
    }

    ul.lst-kix_list_20-2 {
        list-style-type: none
    }

    ul.lst-kix_list_20-3 {
        list-style-type: none
    }

    .lst-kix_list_19-0 > li:before {
        content: "-  "
    }

    .lst-kix_list_19-1 > li:before {
        content: "o  "
    }

    ul.lst-kix_list_20-4 {
        list-style-type: none
    }

    ul.lst-kix_list_20-5 {
        list-style-type: none
    }

    ol.lst-kix_list_17-7.start {
        counter-reset: lst-ctn-kix_list_17-7 0
    }

    ul.lst-kix_list_20-6 {
        list-style-type: none
    }

    ul.lst-kix_list_20-7 {
        list-style-type: none
    }

    ul.lst-kix_list_20-8 {
        list-style-type: none
    }

    .lst-kix_m6920b9r5jdo-5 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-5
    }

    .lst-kix_list_19-4 > li:before {
        content: "o  "
    }

    .lst-kix_list_8-1 > li {
        counter-increment: lst-ctn-kix_list_8-1
    }

    ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0
    }

    .lst-kix_list_19-2 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_19-3 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0
    }

    ul.lst-kix_list_20-0 {
        list-style-type: none
    }

    ul.lst-kix_list_20-1 {
        list-style-type: none
    }

    .lst-kix_list_15-0 > li {
        counter-increment: lst-ctn-kix_list_15-0
    }

    ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0
    }

    .lst-kix_list_7-0 > li {
        counter-increment: lst-ctn-kix_list_7-0
    }

    .lst-kix_list_11-0 > li {
        counter-increment: lst-ctn-kix_list_11-0
    }

    ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0
    }

    ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0
    }

    .lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3
    }

    ul.lst-kix_iwk9uuq7es81-7 {
        list-style-type: none
    }

    ul.lst-kix_iwk9uuq7es81-6 {
        list-style-type: none
    }

    ul.lst-kix_iwk9uuq7es81-5 {
        list-style-type: none
    }

    ul.lst-kix_iwk9uuq7es81-4 {
        list-style-type: none
    }

    ul.lst-kix_iwk9uuq7es81-3 {
        list-style-type: none
    }

    ul.lst-kix_iwk9uuq7es81-2 {
        list-style-type: none
    }

    ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0
    }

    ul.lst-kix_iwk9uuq7es81-1 {
        list-style-type: none
    }

    ul.lst-kix_iwk9uuq7es81-0 {
        list-style-type: none
    }

    .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2
    }

    .lst-kix_list_19-8 > li:before {
        content: "\\0025aa   "
    }

    ol.lst-kix_list_14-7.start {
        counter-reset: lst-ctn-kix_list_14-7 0
    }

    ol.lst-kix_list_11-2.start {
        counter-reset: lst-ctn-kix_list_11-2 0
    }

    ul.lst-kix_iwk9uuq7es81-8 {
        list-style-type: none
    }

    .lst-kix_list_5-2 > li {
        counter-increment: lst-ctn-kix_list_5-2
    }

    .lst-kix_list_19-5 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_19-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_19-7 > li:before {
        content: "o  "
    }

    .lst-kix_list_9-2 > li {
        counter-increment: lst-ctn-kix_list_9-2
    }

    ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0
    }

    .lst-kix_list_17-2 > li {
        counter-increment: lst-ctn-kix_list_17-2
    }

    ol.lst-kix_list_17-2.start {
        counter-reset: lst-ctn-kix_list_17-2 0
    }

    .lst-kix_list_13-2 > li {
        counter-increment: lst-ctn-kix_list_13-2
    }

    .lst-kix_list_14-3 > li {
        counter-increment: lst-ctn-kix_list_14-3
    }

    ol.lst-kix_list_15-6.start {
        counter-reset: lst-ctn-kix_list_15-6 0
    }

    ol.lst-kix_m6920b9r5jdo-2.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-2 0
    }

    ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0
    }

    ol.lst-kix_m6920b9r5jdo-7.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-7 0
    }

    .lst-kix_list_18-0 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0
    }

    .lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0
    }

    .lst-kix_list_18-1 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_18-2 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0
    }

    li.li-bullet-2:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    ol.lst-kix_list_11-7.start {
        counter-reset: lst-ctn-kix_list_11-7 0
    }

    ol.lst-kix_list_14-2.start {
        counter-reset: lst-ctn-kix_list_14-2 0
    }

    ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0
    }

    ol.lst-kix_list_1-3 {
        list-style-type: none
    }

    ol.lst-kix_list_1-4 {
        list-style-type: none
    }

    .lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
    }

    .lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7
    }

    ol.lst-kix_list_1-5 {
        list-style-type: none
    }

    ol.lst-kix_list_1-6 {
        list-style-type: none
    }

    ol.lst-kix_list_1-0 {
        list-style-type: none
    }

    .lst-kix_list_2-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-5, lower-roman) ") "
    }

    ol.lst-kix_list_1-1 {
        list-style-type: none
    }

    .lst-kix_list_17-5 > li {
        counter-increment: lst-ctn-kix_list_17-5
    }

    ol.lst-kix_list_1-2 {
        list-style-type: none
    }

    ol.lst-kix_list_17-0.start {
        counter-reset: lst-ctn-kix_list_17-0 0
    }

    .lst-kix_list_18-6 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0
    }

    .lst-kix_list_14-6 > li {
        counter-increment: lst-ctn-kix_list_14-6
    }

    .lst-kix_list_10-1 > li:before {
        content: " "
    }

    .lst-kix_list_18-4 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_18-8 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_17-6 > li {
        counter-increment: lst-ctn-kix_list_17-6
    }

    .lst-kix_list_7-7 > li {
        counter-increment: lst-ctn-kix_list_7-7
    }

    ol.lst-kix_list_15-1.start {
        counter-reset: lst-ctn-kix_list_15-1 0
    }

    ol.lst-kix_list_15-4.start {
        counter-reset: lst-ctn-kix_list_15-4 0
    }

    ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0
    }

    ol.lst-kix_list_1-7 {
        list-style-type: none
    }

    ol.lst-kix_list_1-8 {
        list-style-type: none
    }

    .lst-kix_list_10-7 > li:before {
        content: " "
    }

    .lst-kix_list_7-8 > li {
        counter-increment: lst-ctn-kix_list_7-8
    }

    .lst-kix_list_10-5 > li:before {
        content: " "
    }

    ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0
    }

    li.li-bullet-1:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_list_10-3 > li:before {
        content: " "
    }

    .lst-kix_list_15-4 > li {
        counter-increment: lst-ctn-kix_list_15-4
    }

    .lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6
    }

    ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0
    }

    ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0
    }

    .lst-kix_list_11-7 > li {
        counter-increment: lst-ctn-kix_list_11-7
    }

    .lst-kix_list_9-2 > li:before {
        content: "" counter(lst-ctn-kix_list_9-2, lower-latin) ") "
    }

    ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0
    }

    ol.lst-kix_list_14-0.start {
        counter-reset: lst-ctn-kix_list_14-0 0
    }

    .lst-kix_list_20-8 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_5-5 > li {
        counter-increment: lst-ctn-kix_list_5-5
    }

    .lst-kix_list_9-0 > li:before {
        content: "\\0000a7  " counter(lst-ctn-kix_list_9-0, decimal) "  "
    }

    .lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4
    }

    ul.lst-kix_list_21-1 {
        list-style-type: none
    }

    ul.lst-kix_list_21-2 {
        list-style-type: none
    }

    ul.lst-kix_list_21-3 {
        list-style-type: none
    }

    ul.lst-kix_list_21-4 {
        list-style-type: none
    }

    .lst-kix_list_20-0 > li:before {
        content: "-  "
    }

    ul.lst-kix_list_21-5 {
        list-style-type: none
    }

    ul.lst-kix_list_21-6 {
        list-style-type: none
    }

    ul.lst-kix_list_21-7 {
        list-style-type: none
    }

    ul.lst-kix_list_21-8 {
        list-style-type: none
    }

    .lst-kix_list_9-6 > li:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". "
    }

    .lst-kix_list_9-4 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-4, lower-latin) ") "
    }

    .lst-kix_list_11-3 > li:before {
        content: "" counter(lst-ctn-kix_list_11-3, decimal) ". "
    }

    ul.lst-kix_list_21-0 {
        list-style-type: none
    }

    .lst-kix_list_6-3 > li {
        counter-increment: lst-ctn-kix_list_6-3
    }

    .lst-kix_list_20-6 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_10-0 {
        list-style-type: none
    }

    ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0
    }

    ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0
    }

    .lst-kix_list_20-4 > li:before {
        content: "o  "
    }

    .lst-kix_list_11-5 > li:before {
        content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". "
    }

    ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0
    }

    .lst-kix_list_20-2 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_9-8 > li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". "
    }

    .lst-kix_list_1-1 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) " "
    }

    .lst-kix_list_11-7 > li:before {
        content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". "
    }

    .lst-kix_list_8-5 > li {
        counter-increment: lst-ctn-kix_list_8-5
    }

    .lst-kix_list_1-3 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) " "
    }

    ul.lst-kix_list_10-8 {
        list-style-type: none
    }

    ol.lst-kix_m6920b9r5jdo-5.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-5 0
    }

    .lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8
    }

    ul.lst-kix_list_10-7 {
        list-style-type: none
    }

    .lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) "." counter(lst-ctn-kix_list_1-7, decimal) " "
    }

    ul.lst-kix_list_10-6 {
        list-style-type: none
    }

    ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0
    }

    ul.lst-kix_list_10-5 {
        list-style-type: none
    }

    ul.lst-kix_list_10-4 {
        list-style-type: none
    }

    ul.lst-kix_list_10-3 {
        list-style-type: none
    }

    .lst-kix_list_1-5 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) " "
    }

    ul.lst-kix_list_10-2 {
        list-style-type: none
    }

    ul.lst-kix_list_10-1 {
        list-style-type: none
    }

    ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0
    }

    .lst-kix_list_14-7 > li {
        counter-increment: lst-ctn-kix_list_14-7
    }

    .lst-kix_list_5-6 > li {
        counter-increment: lst-ctn-kix_list_5-6
    }

    ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0
    }

    .lst-kix_list_2-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-1, lower-latin) ") "
    }

    .lst-kix_list_2-3 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-3, decimal) ") "
    }

    .lst-kix_list_11-8 > li {
        counter-increment: lst-ctn-kix_list_11-8
    }

    .lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1
    }

    .lst-kix_list_9-1 > li {
        counter-increment: lst-ctn-kix_list_9-1
    }

    ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0
    }

    .lst-kix_list_3-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-1, upper-latin) ") "
    }

    .lst-kix_list_3-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-2, upper-latin) ") "
    }

    .lst-kix_m6920b9r5jdo-4 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-4
    }

    .lst-kix_list_14-0 > li {
        counter-increment: lst-ctn-kix_list_14-0
    }

    .lst-kix_list_8-1 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) " "
    }

    ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0
    }

    ol.lst-kix_list_17-4.start {
        counter-reset: lst-ctn-kix_list_17-4 0
    }

    .lst-kix_list_8-2 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) " "
    }

    .lst-kix_list_6-0 > li {
        counter-increment: lst-ctn-kix_list_6-0
    }

    .lst-kix_list_3-5 > li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-latin) ") "
    }

    .lst-kix_list_12-0 > li {
        counter-increment: lst-ctn-kix_list_12-0
    }

    ol.lst-kix_list_11-5.start {
        counter-reset: lst-ctn-kix_list_11-5 0
    }

    .lst-kix_6heel87l2wmr-0 > li:before {
        content: "-  "
    }

    .lst-kix_list_21-2 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_8-5 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) "." counter(lst-ctn-kix_list_8-3, lower-latin) "." counter(lst-ctn-kix_list_8-4, lower-roman) "." counter(lst-ctn-kix_list_8-5, decimal) " "
    }

    .lst-kix_m6920b9r5jdo-6 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-6
    }

    .lst-kix_list_11-1 > li {
        counter-increment: lst-ctn-kix_list_11-1
    }

    .lst-kix_list_8-6 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) "." counter(lst-ctn-kix_list_8-1, decimal) "." counter(lst-ctn-kix_list_8-2, decimal) "." counter(lst-ctn-kix_list_8-3, lower-latin) "." counter(lst-ctn-kix_list_8-4, lower-roman) "." counter(lst-ctn-kix_list_8-5, decimal) "." counter(lst-ctn-kix_list_8-6, decimal) " "
    }

    .lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0
    }

    .lst-kix_list_15-1 > li {
        counter-increment: lst-ctn-kix_list_15-1
    }

    .lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, lower-roman) ") "
    }

    .lst-kix_6heel87l2wmr-4 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_21-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_6heel87l2wmr-5 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_21-7 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0
    }

    .lst-kix_list_11-2 > li:before {
        content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". "
    }

    .lst-kix_8h3ngux134lm-0 > li:before {
        content: "-  "
    }

    .lst-kix_8h3ngux134lm-1 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0
    }

    .lst-kix_6heel87l2wmr-1 > li:before {
        content: "o  "
    }

    .lst-kix_list_21-3 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_8h3ngux134lm-4 > li:before {
        content: "o  "
    }

    .lst-kix_8h3ngux134lm-5 > li:before {
        content: "\\0025aa   "
    }

    ol.lst-kix_list_11-6.start {
        counter-reset: lst-ctn-kix_list_11-6 0
    }

    .lst-kix_list_16-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4
    }

    ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0
    }

    .lst-kix_m6920b9r5jdo-8 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-8
    }

    .lst-kix_list_17-1 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) " "
    }

    ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0
    }

    .lst-kix_iwk9uuq7es81-6 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_6heel87l2wmr-8 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_16-1 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0
    }

    .lst-kix_list_7-3 > li {
        counter-increment: lst-ctn-kix_list_7-3
    }

    .lst-kix_list_16-2 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_iwk9uuq7es81-5 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_8h3ngux134lm-8 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_iwk9uuq7es81-2 > li:before {
        content: "\\0025a0   "
    }

    .lst-kix_list_16-5 > li:before {
        content: "\\0025aa   "
    }

    .lst-kix_list_15-3 > li {
        counter-increment: lst-ctn-kix_list_15-3
    }

    ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0
    }

    .lst-kix_iwk9uuq7es81-1 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_list_11-0.start {
        counter-reset: lst-ctn-kix_list_11-0 0
    }

    .lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3
    }

    ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0
    }

    .lst-kix_list_17-2 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) " "
    }

    ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0
    }

    ol.lst-kix_list_17-3.start {
        counter-reset: lst-ctn-kix_list_17-3 0
    }

    .lst-kix_list_17-6 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) "." counter(lst-ctn-kix_list_17-3, lower-latin) "." counter(lst-ctn-kix_list_17-4, lower-roman) "." counter(lst-ctn-kix_list_17-5, decimal) "." counter(lst-ctn-kix_list_17-6, decimal) " "
    }

    .lst-kix_list_17-5 > li:before {
        content: "" counter(lst-ctn-kix_list_17-0, decimal) "." counter(lst-ctn-kix_list_17-1, decimal) "." counter(lst-ctn-kix_list_17-2, decimal) "." counter(lst-ctn-kix_list_17-3, lower-latin) "." counter(lst-ctn-kix_list_17-4, lower-roman) "." counter(lst-ctn-kix_list_17-5, decimal) " "
    }

    .lst-kix_list_6-2 > li {
        counter-increment: lst-ctn-kix_list_6-2
    }

    .lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
    }

    ul.lst-kix_8h3ngux134lm-0 {
        list-style-type: none
    }

    ul.lst-kix_8h3ngux134lm-1 {
        list-style-type: none
    }

    .lst-kix_list_14-5 > li {
        counter-increment: lst-ctn-kix_list_14-5
    }

    ul.lst-kix_8h3ngux134lm-2 {
        list-style-type: none
    }

    ul.lst-kix_8h3ngux134lm-3 {
        list-style-type: none
    }

    ul.lst-kix_8h3ngux134lm-4 {
        list-style-type: none
    }

    .lst-kix_list_7-1 > li:before {
        content: "" counter(lst-ctn-kix_list_7-0, decimal) "." counter(lst-ctn-kix_list_7-1, decimal) " "
    }

    .lst-kix_list_7-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-5, upper-roman) ") "
    }

    .lst-kix_list_13-5 > li {
        counter-increment: lst-ctn-kix_list_13-5
    }

    ul.lst-kix_8h3ngux134lm-5 {
        list-style-type: none
    }

    .lst-kix_list_9-6 > li {
        counter-increment: lst-ctn-kix_list_9-6
    }

    ol.lst-kix_m6920b9r5jdo-8.start {
        counter-reset: lst-ctn-kix_m6920b9r5jdo-8 0
    }

    ul.lst-kix_8h3ngux134lm-6 {
        list-style-type: none
    }

    ul.lst-kix_8h3ngux134lm-7 {
        list-style-type: none
    }

    ul.lst-kix_8h3ngux134lm-8 {
        list-style-type: none
    }

    ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0
    }

    ol.lst-kix_list_11-1.start {
        counter-reset: lst-ctn-kix_list_11-1 0
    }

    .lst-kix_list_18-5 > li:before {
        content: "\\0025cf   "
    }

    .lst-kix_list_13-6 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) "." counter(lst-ctn-kix_list_13-3, lower-latin) "." counter(lst-ctn-kix_list_13-4, lower-roman) "." counter(lst-ctn-kix_list_13-5, decimal) "." counter(lst-ctn-kix_list_13-6, decimal) " "
    }

    ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0
    }

    .lst-kix_list_6-7 > li {
        counter-increment: lst-ctn-kix_list_6-7
    }

    .lst-kix_m6920b9r5jdo-3 > li:before {
        content: "(" counter(lst-ctn-kix_m6920b9r5jdo-3, lower-latin) ") "
    }

    .lst-kix_list_11-6 > li {
        counter-increment: lst-ctn-kix_list_11-6
    }

    .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7
    }

    ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0
    }

    .lst-kix_list_7-5 > li {
        counter-increment: lst-ctn-kix_list_7-5
    }

    .lst-kix_list_15-6 > li:before {
        content: "" counter(lst-ctn-kix_list_15-6, decimal) ". "
    }

    ol.lst-kix_list_17-8.start {
        counter-reset: lst-ctn-kix_list_17-8 0
    }

    .lst-kix_list_11-4 > li {
        counter-increment: lst-ctn-kix_list_11-4
    }

    .lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8
    }

    ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0
    }

    .lst-kix_list_10-2 > li:before {
        content: " "
    }

    .lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6
    }

    .lst-kix_list_13-7 > li {
        counter-increment: lst-ctn-kix_list_13-7
    }

    ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0
    }

    .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5
    }

    .lst-kix_list_20-7 > li:before {
        content: "o  "
    }

    ol.lst-kix_list_17-5.start {
        counter-reset: lst-ctn-kix_list_17-5 0
    }

    ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0
    }

    .lst-kix_list_4-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-2, lower-roman) ") "
    }

    .lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-0, lower-roman) "." counter(lst-ctn-kix_list_4-1, lower-roman) "." counter(lst-ctn-kix_list_4-2, lower-roman) "." counter(lst-ctn-kix_list_4-3, decimal) "." counter(lst-ctn-kix_list_4-4, decimal) "." counter(lst-ctn-kix_list_4-5, decimal) "." counter(lst-ctn-kix_list_4-6, decimal) ". "
    }

    .lst-kix_list_17-4 > li {
        counter-increment: lst-ctn-kix_list_17-4
    }

    .lst-kix_list_15-2 > li:before {
        content: "" counter(lst-ctn-kix_list_15-2, lower-roman) ". "
    }

    .lst-kix_list_10-6 > li:before {
        content: " "
    }

    .lst-kix_list_9-1 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-1, decimal) ") "
    }

    .lst-kix_list_15-8 > li {
        counter-increment: lst-ctn-kix_list_15-8
    }

    ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0
    }

    .lst-kix_list_9-5 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-5, lower-roman) ") "
    }

    .lst-kix_m6920b9r5jdo-1 > li {
        counter-increment: lst-ctn-kix_m6920b9r5jdo-1
    }

    .lst-kix_list_12-2 > li:before {
        content: " "
    }

    .lst-kix_list_11-6 > li:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". "
    }

    .lst-kix_list_20-3 > li:before {
        content: "\\0025cf   "
    }

    ol.lst-kix_list_11-3.start {
        counter-reset: lst-ctn-kix_list_11-3 0
    }

    .lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) " "
    }

    .lst-kix_m6920b9r5jdo-7 > li:before {
        content: "" counter(lst-ctn-kix_m6920b9r5jdo-0, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-1, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-2, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-3, lower-latin) "." counter(lst-ctn-kix_m6920b9r5jdo-4, lower-roman) "." counter(lst-ctn-kix_m6920b9r5jdo-5, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-6, decimal) "." counter(lst-ctn-kix_m6920b9r5jdo-7, decimal) " "
    }

    .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0
    }

    .lst-kix_list_8-8 > li {
        counter-increment: lst-ctn-kix_list_8-8
    }

    ol.lst-kix_list_17-6.start {
        counter-reset: lst-ctn-kix_list_17-6 0
    }

    .lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-0, decimal) "." counter(lst-ctn-kix_list_1-1, decimal) "." counter(lst-ctn-kix_list_1-2, decimal) "." counter(lst-ctn-kix_list_1-3, decimal) "." counter(lst-ctn-kix_list_1-4, decimal) "." counter(lst-ctn-kix_list_1-5, decimal) "." counter(lst-ctn-kix_list_1-6, decimal) " "
    }

    li.li-bullet-0:before {
        margin-left: -36pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 36pt
    }

    .lst-kix_list_12-6 > li:before {
        content: " "
    }

    ol.lst-kix_list_11-4.start {
        counter-reset: lst-ctn-kix_list_11-4 0
    }

    .lst-kix_list_2-2 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-2, lower-latin) ") "
    }

    .lst-kix_list_13-2 > li:before {
        content: "" counter(lst-ctn-kix_list_13-0, decimal) "." counter(lst-ctn-kix_list_13-1, decimal) "." counter(lst-ctn-kix_list_13-2, decimal) " "
    }

    .lst-kix_ieh6bbjxy2lo-1 > li:before {
        content: "\\0025cb   "
    }

    ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c27 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #d9d9e3;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #d9d9e3;
        padding-bottom: 0pt;
        line-height: 1.263157894736842;
        border-right-color: #d9d9e3;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #d9d9e3;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left;
        padding-right: 0pt
    }

    .c37 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #d9d9e3;
        border-right-width: 0pt;
        border-left-color: #d9d9e3;
        padding-bottom: 0pt;
        line-height: 1.263157894736842;
        border-right-color: #d9d9e3;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #d9d9e3;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left;
        padding-right: 0pt
    }

    .c55 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 120.2pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c56 {
        border-right-style: solid;
        padding: 0pt 5.2pt 0pt 5.2pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 95.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c23 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 113.4pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c39 {
        border-right-style: solid;
        padding: 0pt 5.2pt 0pt 5.2pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 118.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c69 {
        border-right-style: solid;
        padding: 0pt 5.2pt 0pt 5.2pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 147.7pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c18 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 155.9pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c28 {
        border-right-style: solid;
        padding: 0pt 5.2pt 0pt 5.2pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 116.6pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c61 {
        border-right-style: solid;
        padding: 5pt 5pt 5pt 5pt;
        border-bottom-color: #808080;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #d9d9e3;
        vertical-align: bottom;
        border-right-color: #808080;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225.7pt;
        border-top-color: #d9d9e3;
        border-bottom-style: solid
    }

    .c13 {
        border-right-style: solid;
        padding: 0pt 5.4pt 0pt 5.4pt;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 70.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c33 {
        border-right-style: solid;
        padding: 5pt 5pt 5pt 5pt;
        border-bottom-color: #808080;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #808080;
        vertical-align: top;
        border-right-color: #808080;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 225.7pt;
        border-top-color: #808080;
        border-bottom-style: solid
    }

    .c47 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 5pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c57 {
        margin-left: 18pt;
        padding-top: 5pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c20 {
        background-color: #ffffff;
        color: #0b0631;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Roboto";
        font-style: normal
    }

    .c9 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c7 {
        background-color: #ffffff;
        color: #0b0631;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Roboto";
        font-style: normal
    }

    .c62 {
        margin-left: 18pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c2 {
        margin-left: 18pt;
        padding-top: 5pt;
        padding-left: 18pt;
        padding-bottom: 5pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c24 {
        margin-left: 54pt;
        padding-top: 6pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c15 {
        margin-left: 54pt;
        padding-top: 0pt;
        padding-left: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Arial";
        font-style: normal
    }

    .c11 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Arial Nova Light";
        font-style: normal
    }

    .c0 {
        margin-left: 0pt;
        padding-top: 12pt;
        list-style-position: inside;
        text-indent: 45pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: justify
    }

    .c4 {
        margin-left: 60.5pt;
        padding-top: 12pt;
        padding-left: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        page-break-after: avoid;
        text-align: justify
    }

    .c10 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 9pt;
        font-family: "Arial";
        font-style: normal
    }

    .c68 {
        padding-top: 10pt;
        padding-bottom: 10pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c30 {
        padding-top: 5.2pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c52 {
        padding-top: 5pt;
        padding-bottom: 5pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c19 {
        -webkit-text-decoration-skip: none;
        font-weight: 700;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 9pt;
        font-family: "Arial"
    }

    .c17 {
        margin-left: 60.5pt;
        padding-top: 12pt;
        padding-left: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: justify
    }

    .c46 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c12 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c14 {
        margin-left: 60.5pt;
        padding-top: 6pt;
        padding-left: 18pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: justify
    }

    .c3 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    .c44 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.0;
        text-align: left
    }

    .c64 {
        color: #000000;
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Arial"
    }

    .c29 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.71429;
        text-align: center
    }

    .c54 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.6;
        text-align: center
    }

    .c53 {
        padding-top: 12pt;
        padding-bottom: 6pt;
        line-height: 1.0;
        text-align: justify
    }

    .c59 {
        margin-left: 0.3pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c21 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.6;
        text-align: left
    }

    .c36 {
        font-weight: 400;
        text-decoration: none;
        font-size: 11pt;
        font-family: "Arial"
    }

    .c60 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c43 {
        background-color: #ffffff;
        max-width: 451.4pt;
        padding: 72pt 72pt 72pt 72pt
    }

    .c50 {
        text-decoration: none;
        font-size: 7pt;
        font-family: "Arial"
    }

    .c25 {
        color: #000000;
        vertical-align: baseline;
        font-style: normal
    }

    .c40 {
        text-decoration: none;
        font-size: 8pt;
        font-family: "Arial"
    }

    .c45 {
        text-decoration: none;
        font-size: 11pt;
        font-family: "Arial Nova Light"
    }

    .c26 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        text-decoration: underline
    }

    .c58 {
        text-decoration: none;
        font-size: 8pt;
        font-family: "Arial Nova Light"
    }

    .c67 {
        font-size: 8pt;
        font-style: normal
    }

    .c32 {
        margin-left: 18pt;
        padding-left: 18pt
    }

    .c65 {
        font-size: 7pt;
        font-style: normal
    }

    .c70 {
        font-size: 11pt;
        font-style: normal
    }

    .c5 {
        color: inherit;
        text-decoration: inherit
    }

    .c41 {
        font-size: 9pt;
        font-style: italic
    }

    .c35 {
        font-size: 9pt;
        font-family: "Arial"
    }

    .c42 {
        margin-left: 54pt;
        padding-left: 0pt
    }

    .c22 {
        border: 1px solid black;
        margin: 5px
    }

    .c63 {
        padding-left: 18pt;
        margin-left: 60.5pt
    }

    .c8 {
        padding: 0;
        margin: 0
    }

    .c71 {
        color: #161616
    }

    .c16 {
        height: 11pt
    }

    .c6 {
        height: 0pt
    }

    .c49 {
        height: 9pt
    }

    .c66 {
        color: #0000ff
    }

    .c48 {
        font-size: 9pt
    }

    .c72 {
        font-style: italic
    }

    .c38 {
        font-weight: 400
    }

    .c51 {
        background-color: #ffffff
    }

    .c31 {
        page-break-after: avoid
    }

    .c34 {
        font-weight: 700
    }

    .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Arial Nova Light";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.0;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial Nova Light"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial Nova Light"
    }

    h1 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 9pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.0;
        text-align: left
    }

    h2 {
        padding-top: 5pt;
        color: #000000;
        font-size: 9pt;
        padding-bottom: 5pt;
        font-family: "Arial";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h3 {
        padding-top: 6pt;
        color: #000000;
        font-size: 9pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: justify
    }

    h4 {
        padding-top: 6pt;
        color: #000000;
        font-size: 11pt;
        padding-bottom: 6pt;
        font-family: "Arial Nova Light";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 6pt;
        color: #000000;
        font-size: 11pt;
        padding-bottom: 6pt;
        font-family: "Arial Nova Light";
        line-height: 1.0;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 3pt;
        font-family: "Times New Roman";
        line-height: 1.0;
        orphans: 2;
        widows: 2;
        text-align: left
    }
`;
export default () => {
  return (
    <AnimationRevealPage>
      <Style />
      <div className="p-4">
        <p className="c3">
          <span className="c10">Allgemeine Gesch&auml;ftsbedingungen</span>
        </p>
        <ol className="c8 lst-kix_list_8-0 start" start="1">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Pr&auml;ambel</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>Die </span>
              <span className="c51">Foodamigos GmbH</span>
              <span className="c51">, </span>
              <span className="c51 c71">Am Hauptbahnhof 6, 53111 Bonn </span>
              <span>
                (nachfolgend: Anbieter) ist ein Softwarehersteller und
                technischer Dienstleister und bietet Inhabern von Restaurants,
                Gastst&auml;tten, Bistros, Caf&eacute;s und &auml;hnlichen
                Gesch&auml;ften (im Folgenden: Partner) &uuml;ber eine
                Softwareplattform die Erstellung und den Betrieb eines
                Internetsauftritts und technischer Infrastruktur f&uuml;r
                einzelne Standorte (im Folgenden: Plattform). Die Plattform
                erm&ouml;glicht es Partnern, sich im Internet zu
                pr&auml;sentieren und Getr&auml;nke, Essen oder &Auml;hnliches
                (im Folgenden: Produkte) an Endkunden zu vertreiben. Weitere
                Informationen &uuml;ber die Plattform finden sich auf der
                Website des Anbieters{" "}
              </span>
              <span className="c26 c35 c66 c38">
                <a
                  className="c5"
                  href="https://www.google.com/url?q=http://www.foodamigos.io&amp;sa=D&amp;source=editors&amp;ust=1716562474436350&amp;usg=AOvVaw18a5Lgfpu6RkK2T2_X-Uku"
                >
                  www.
                </a>
              </span>
              <span className="c26 c66">
                <a
                  className="c5"
                  href="https://www.google.com/url?q=http://www.foodamigos.io&amp;sa=D&amp;source=editors&amp;ust=1716562474436610&amp;usg=AOvVaw1dn685rT5uP-z0Rr3B02o-"
                >
                  foodamigos
                </a>
              </span>
              <span className="c26 c35 c38 c66">
                <a
                  className="c5"
                  href="https://www.google.com/url?q=http://www.foodamigos.io&amp;sa=D&amp;source=editors&amp;ust=1716562474436725&amp;usg=AOvVaw3NF70Cop0MjxDTKvOuGWB-"
                >
                  .io
                </a>
              </span>
              <span className="c1">.</span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Diese Allgemeinen Gesch&auml;ftsbedingungen (im Folgenden: AGB)
                regeln die Bedingungen f&uuml;r einzelne Vertr&auml;ge, die
                &uuml;ber das Anmeldeformular abgeschlossen werden.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="2">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Vertragsschluss</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter wird ein verbindliches Angebot zum Abschluss dieses
                Vertrags nur abgeben oder das verbindliche Angebot des Partners
                nur akzeptieren, wenn der Partner ein Unternehmen oder eine
                Person ist, die in Aus&uuml;bung ihres Handelsgesch&auml;fts
                oder Berufs t&auml;tig ist.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Das Bereitstellen des Anmeldeformulars ist das verbindliche
                Angebot des Anbieters, mit dem Partner einen Vertrag nach
                Ma&szlig;gabe der Bestimmungen dieser AGB abzuschlie&szlig;en.
                Der Partner ist berechtigt, aber nicht verpflichtet, dieses
                Angebot anzunehmen. Der Partner erkl&auml;rt die Annahme des
                Vertrags durch Unterschrift auf dem Anmeldeformular und
                Anklicken des Buttons mit der Aufschrift &bdquo;kostenpflichtig
                Abschlie&szlig;en&ldquo; (oder einer sinngem&auml;&szlig;
                identischen Aufschrift). Die Annahme wird dadurch elektronisch
                an den Anbieter &uuml;bermittelt. Der Anbieter wird dem Partner
                mittels Erfolgsmeldung oder &Uuml;bersendung einer
                entsprechenden Erkl&auml;rung per E-Mail den Vertragsschluss
                best&auml;tigen.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="3">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Leistungsumfang</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Nach Vertragsschluss erstellt der Anbieter dem Partner einen
                Internetauftritt und Infrastruktur f&uuml;r den Vertrieb von
                Produkten auf der Plattform f&uuml;r den im Anmeldeformular
                vereinbarten Standort des Partner mit den im Anmeldeformular
                vereinbarten Funktionalit&auml;ten und Einschr&auml;nkungen. Der
                Anbieter stellt dem Partner w&auml;hrend der Vertragslaufzeit
                Hardware inklusive Software, Zubeh&ouml;r und
                Verbrauchsmaterialien in dem im Anmeldeformular vereinbarten
                Umfang zur Nutzung der Plattform zur Verf&uuml;gung (siehe
                Ziffer 6 &bdquo;Hardware&ldquo;).
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter gew&auml;hrt dem Partner nach erfolgreicher
                Erstellung einen Zugang zur Nutzung der Plattform &uuml;ber das
                Internet durch die Bereitstellung von Zugangsdaten.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner ist berechtigt aber nicht verpflichtet, &uuml;ber
                die Plattform Vertr&auml;ge mit Endkunde zu schlie&szlig;en. Der
                Anbieter wird dabei nicht selbst Vertragspartner. Er stellt
                lediglich den technischen Rahmen zur sicheren &Uuml;bertragung
                zur Verf&uuml;gung. Diese Vertr&auml;ge berechtigen und
                verpflichten ausschlie&szlig;lich den beteiligten Partner und
                den Endkunden.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Der Anbieter kann den Partner regelm&auml;&szlig;ig &uuml;ber
                neue Entwicklungen
              </span>
              <span>
                &nbsp;und Aktivit&auml;ten auf der Plattform per E-Mail
                informieren und Feedback des Partners abfragen. Der Partner kann
                dem Versand solcher E-Mails jederzeit &uuml;ber einen Link in
                der Fu&szlig;zeile jeder E-Mail oder &uuml;ber die
                Profil-Einstellungen im Nutzerkonto abbestellen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter ist berechtigt, im Rahmen der Erbringung
                s&auml;mtlicher Leistungen nach diesem Vertrag
                Unterauftragnehmer einzusetzen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter ist nicht verpflichtet, eine Internetverbindung
                zwischen dem Webserver und dem Internet-Zugangspunkt des
                Partners herzustellen und aufrechtzuerhalten.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Beseitigung von Fehlern und Sch&auml;den, die durch
                unsachgem&auml;&szlig;e Handhabung durch den Partner, durch den
                Einfluss Dritter oder durch h&ouml;here Gewalt verursacht
                werden, ist nicht Gegenstand dieses Vertrags, kann aber im
                Einzelfall als professionelle Dienstleistung beauftragt werden.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="4">
          <li className="c12 c63 li-bullet-0">
            <span className="c10">Verbotene Artikel</span>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c32 c51 c68 li-bullet-0">
            <span className="c35 c38">
              Die folgenden Artikel d&uuml;rfen nicht gepostet oder verkauft
              werden, insbesondere nicht f&uuml;r Paypal-Zahlungen.
            </span>
          </li>
        </ol>
        <a id="t.11f06fee9288884404d9f063f742c0225c7c7270"></a>
        <a id="t.0"></a>
        <table className="c60">
          <tr className="c6">
            <td className="c61" colSpan="1" rowSpan="1">
              <p className="c29">
                <span className="c1">Verbotener Artikel</span>
              </p>
            </td>
            <td className="c33" colSpan="1" rowSpan="1">
              <p className="c21">
                <span className="c1">Description</span>
              </p>
            </td>
          </tr>
          <tr className="c6">
            <td className="c33" colSpan="1" rowSpan="1">
              <p className="c54">
                <span className="c1">Alkohol</span>
              </p>
            </td>
            <td className="c33" colSpan="1" rowSpan="1">
              <p className="c21">
                <span className="c1">Verkauf alkoholischer Getr&auml;nke</span>
              </p>
            </td>
          </tr>
          <tr className="c6">
            <td className="c33" colSpan="1" rowSpan="1">
              <p className="c54">
                <span className="c1">Tabak</span>
              </p>
            </td>
            <td className="c33" colSpan="1" rowSpan="1">
              <p className="c21">
                <span className="c1">
                  Nicht-Zigaretten-Tabakprodukte, E-Zigaretten, Zigarren
                </span>
              </p>
            </td>
          </tr>
        </table>
        <h3 className="c27 c49" id="h.wpxy9zuh1eap">
          <span className="c10"></span>
        </h3>
        <ol className="c8 lst-kix_list_8-0" start="5">
          <li className="c37 c63 li-bullet-0">
            <h3 id="h.7u4o9486n44r" style={{ display: "inline" }}>
              <span className="c34">
                Onboarding- und Pr&uuml;fungsrichtlinien
              </span>
            </h3>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 id="h.57o1uj43ztnk" style={{ display: "inline" }}>
              <span>
                Produktpr&uuml;fung und -verifizierung: Foodamigos GmbH
                &uuml;berpr&uuml;ft und verifiziert alle &uuml;ber unsere
                Plattform verkauften Waren rigoros, um die Einhaltung
                gesetzlicher und regulatorischer Anforderungen, die Einhaltung
                unserer Richtlinie f&uuml;r verbotene Artikel und genaue
                Produktbeschreibungen zu gew&auml;hrleisten, die eine
                informierte Entscheidungsfindung der Kunden erm&ouml;glichen.
              </span>
              <span className="c1">&nbsp;</span>
            </h2>
          </li>
          <li className="c32 c37 li-bullet-0">
            <h3 id="h.exbqndvil5h6" style={{ display: "inline" }}>
              <span>
                Vorabgenehmigung neuer Artikel: Restaurants m&uuml;ssen die
                Genehmigung von Foodamigos GmbH einholen, bevor sie neue Waren
                auf unserer Plattform anbieten. Dieser Vorabgenehmigungsprozess
                sch&uuml;tzt vor der Einf&uuml;hrung verbotener oder nicht
                konformer Artikel.
              </span>
            </h3>
          </li>
          <li className="c37 c32 li-bullet-0">
            <h3 id="h.yinc346ny802" style={{ display: "inline" }}>
              <span>
                Von H&auml;ndlern zubereitete Produkte: Foodamigos GmbH bietet
                ausschlie&szlig;lich Speisen und Getr&auml;nke an, die von
                unseren Partnerh&auml;ndlern zubereitet und verkauft werden.
                Eine umfassende Liste verbotener Artikel finden Sie in unserer
                Richtlinie f&uuml;r verbotene Artikel
              </span>
              <span className="c7">.</span>
            </h3>
          </li>
          <li className="c37 c32 li-bullet-0">
            <h3 id="h.tugdh5bgkwe2" style={{ display: "inline" }}>
              <span>
                Laufende &Uuml;berpr&uuml;fungen des Inventars und der Artikel:
                Foodamigos GmbH f&uuml;hrt regelm&auml;&szlig;ige
                &Uuml;berpr&uuml;fungen des Inventars und der Artikel unserer
                H&auml;ndler durch, um die fortgesetzte Einhaltung unserer
                Onboarding- und Pr&uuml;fstandards sicherzustellen.
              </span>
            </h3>
          </li>
          <li className="c37 c32 li-bullet-0">
            <h3 id="h.gmpsatlkm54h" style={{ display: "inline" }}>
              <span className="c1">
                Verantwortung der H&auml;ndler f&uuml;r die Einhaltung von
                Vorschriften: Alle H&auml;ndler sind allein verantwortlich
                f&uuml;r die Einhaltung aller anwendbaren lokalen und
                bundesstaatlichen Vorschriften. Jede Nichteinhaltung oder
                illegale Aktivit&auml;t kann den zust&auml;ndigen Beh&ouml;rden
                gemeldet werden.
              </span>
            </h3>
          </li>
        </ol>
        <h3 className="c27" id="h.e3atc5crwu2v">
          <span className="c34">
            Nichteinhaltung der Onboarding- und Pr&uuml;fungsrichtlinien von
            FoodAmigos
          </span>
        </h3>
        <p className="c46">
          <span className="c35 c38">Jeder Versuch,</span>
          <span className="c35 c38">
            &nbsp;Artikel &uuml;ber FoodAmigos unzul&auml;ssig aufzulisten oder
            zu umgehen, f&uuml;hrt zu einer sofortigen Warnung und/oder
            Entfernung der Seite des Verk&auml;ufers (Restaurant). FoodAmigos
            wird dann eine gr&uuml;ndliche &Uuml;berpr&uuml;fung der Handlungen
            des H&auml;ndlers durchf&uuml;hren und die Konsequenzen festlegen.
            In F&auml;llen, in denen das Handeln eines H&auml;ndlers Personen
            schaden oder den Verkauf illegaler oder verbotener Artikel
            beinhalten k&ouml;nnte, kann FoodAmigos nach eigenem Ermessen den
            Vorfall den zust&auml;ndigen Beh&ouml;rden melden.
          </span>
        </p>
        <ol className="c8 lst-kix_list_8-0" start="6">
          <li className="c17 li-bullet-0">
            <h1 id="h.op34j5g7213c" style={{ display: "inline" }}>
              <span className="c10">Lizenzumfang; IP</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Mit Beginn dieses Vertrags gew&auml;hrt der Anbieter dem Partner
                ein nicht ausschlie&szlig;liches, nicht &uuml;bertragbares,
                nicht unterlizenzierbares, unwiderrufliches (w&auml;hrend der
                Laufzeit), weltweites Recht zur Nutzung der Plattform f&uuml;r
                die vereinbarten Standorte des Partners im Rahmen des Vertrags.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner darf die Plattform nur f&uuml;r den in diesem
                Vertrag definierten Zweck nutzen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter kann weitere Lizenzbeschr&auml;nkungen im
                Anmeldeformular festlegen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner gew&auml;hrt dem Anbieter mit Vertragsschluss das
                weltweite, unwiderrufliche, unbefristete, nicht
                ausschlie&szlig;liche, unentgeltliche, &uuml;bertragbare und
                unterlizenzierbare Recht, Inhalte wie Bilder, Texte,
                Speisekarten, und &Auml;hnliches zum Zwecke der Darstellung und
                der Leistungserbringung auf der Plattform anzuzeigen und zu
                verarbeiten.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="7">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span>Rechte und Pflichten </span>
              <span className="c35 c34">des</span>
              <span className="c10">&nbsp;Partners</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner hat dem Anbieter bei Vertragsschluss und
                w&auml;hrend der Laufzeit dieses Vertrags stets zutreffende
                Informationen &uuml;ber sein Unternehmen und die Nutzung der
                Plattform, insbesondere die Anzahl der Standorte, f&uuml;r die
                die Plattform verwendet wird, zu &uuml;bermitteln und den
                Anbieter zu informieren, wenn sich die &uuml;bermittelten
                Informationen &auml;ndern.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner stellt dem Anbieter Informationen und Inhalte wie
                Texte, Bilder, Speisekarten und Preise zur Verf&uuml;gung, um
                den Internetauftritt zu erstellen. Der Partner hat bei der
                &Uuml;bermittlung von Inhalten wie Texte und Bildern etwaige
                Rechte Dritter zu beachten.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Nach Mitteilung &uuml;ber die Fertigstellung des
                Internetauftritts, wird der Partner unverz&uuml;glich die
                Abnahmef&auml;higkeit pr&uuml;fen und die Abnahme erkl&auml;ren.
                F&uuml;r die Abnahme gelten die gesetzlichen Vorschriften.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner ist verpflichtet, die Plattform ausschlie&szlig;lich
                &uuml;ber Standardbrowser (Chrome, Edge, Firefox oder Safari) in
                der jeweils aktuellen Version aufzurufen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner hat die Zugangsdaten zur Plattform geheim zu halten
                und darf diese nicht an Dritte weitergeben. Eine Weitergabe darf
                nur an Mitarbeiter erfolgen, die die Zugangsdaten zur Nutzung
                der Plattform zwingend ben&ouml;tigen (im Folgenden: berechtigte
                Nutzer). Der Partner muss sicherstellen, dass berechtigte Nutzer
                ihre Zugangsdaten nicht an Dritte weitergeben.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner ist verpflichtet, eingegebene Inhalte und Daten auch
                au&szlig;erhalb der Plattform zu sichern.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner stellt den Anbieter auf erstes Anfordern von allen
                Anspr&uuml;chen Dritter frei, die diese gegen den Anbieter mit
                der Begr&uuml;ndung geltend machen, dass eine vom Partner
                durchgef&uuml;hrte Handlung und/oder vom Partner bereitgestellte
                Inhalte ihre Rechte verletzen.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="8">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Hardware</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner ist verpflichtet, die Hardware vor Beginn des
                geplanten Einsatzes durch Inbetriebnahme auf
                ordnungsgem&auml;&szlig;e Funktion zu &uuml;berpr&uuml;fen.
                Stellt er dabei M&auml;ngel fest, so ist er verpflichtet, dem
                Anbieter diese unverz&uuml;glich anzuzeigen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die initiale Einrichtung der Hardware am Standort des Partners
                erfolgt durch den Partner selbst. Der Anbieter gew&auml;hrt
                dabei Support per E-Mail und Telefon.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter stellt die Funktionst&uuml;chtigkeit der Hardware
                w&auml;hrend der Vertragslaufzeit sicher und wird auftretende
                Fehler und Sch&auml;den beheben oder nach Wahl ein
                Ersatzger&auml;t zur Verf&uuml;gung stellen, sofern die
                auftretenden Fehler und Sch&auml;den nicht auf eine
                Besch&auml;digung oder Fehlnutzung des Partners oder Dritter
                oder einen Diebstahl zur&uuml;ckzuf&uuml;hren sind.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner hat den Anbieter bei Fehlern und Sch&auml;den
                innerhalb von 14 Tagen nach Kenntnis zu informieren und dem
                Anbieter nach Aufforderung zur&uuml;ckzusenden.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                F&uuml;r weitere Verbrauchsmaterialien wie Druckerrollen und
                Flyer &uuml;ber den im Anmeldeformular vereinbarten Umfang kommt
                der Anbieter nicht auf.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Partner ist verpflichtet, die Hardware inklusive Software,
                Zubeh&ouml;r und noch vorhandenen Verbrauchsmaterialien
                unverz&uuml;glich nach Ende des Vertrags auf eigene Kosten an
                den Anbieter zur&uuml;ckzusenden. Die R&uuml;cksendung muss an
                die aktuelle auf der Website unter &ldquo;Impressum&rdquo;
                angegebene Adresse erfolgen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Sofern der Partner die Hardware nicht gem&auml;&szlig; Ziffer
                6.6 rechtzeitig zur&uuml;ckgibt oder die Hardware nicht
                funktionst&uuml;chtig oder &uuml;berm&auml;&szlig;ig
                besch&auml;digt ist, ohne dass der Partner den Anbieter
                gem&auml;&szlig; Ziffer 6.4. dar&uuml;ber informiert hat, ist
                der Anbieter berechtigt, statt der R&uuml;ckgabe den aktuellen
                Wiederbeschaffungswert der Hardware zu verlangen. Der Partner
                ist berechtigt nachzuweisen, dass dem Anbieter ein Schaden
                &uuml;berhaupt nicht entstanden ist oder ein wesentlich geringer
                Schaden als die Pauschale entstanden ist.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="9">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Verf&uuml;gbarkeit</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Der Anbieter bietet f&uuml;r die Plattform eine
                Verf&uuml;gbarkeit von{" "}
              </span>
              <span>98</span>
              <span className="c1">
                % im Jahresmittel. Im Falle k&uuml;rzerer Laufzeiten bezieht
                sich die Verf&uuml;gbarkeit auf die jeweilige Laufzeit.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Plattform ist verf&uuml;gbar, wenn die Nutzung m&ouml;glich
                ist.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Bei der Berechnung der tats&auml;chlichen Verf&uuml;gbarkeiten
                gelten dem Anbieter nicht zurechenbare Ausfallzeiten als
                verf&uuml;gbare Zeiten. Diese unsch&auml;dlichen Ausfallzeiten
                sind:
              </span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_list_21-0 start">
          <li className="c57 li-bullet-1">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                mit dem Partner abgestimmte Wartungs- oder sonstige Leistungen,
                durch die ein Zugriff auf die Plattform nicht m&ouml;glich ist;
              </span>
            </h2>
          </li>
          <li className="c62 li-bullet-2">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                unvorhergesehen erforderlich werdende Wartungsarbeiten, wenn
                diese Arbeiten nicht durch eine Verletzung der Pflichten des
                Anbieters zur Leistungserbringung verursacht wurden (h&ouml;here
                Gewalt, insbesondere nicht vorhersehbare Hardwareausf&auml;lle,
                Streiks, Naturereignisse etc.);
              </span>
            </h2>
          </li>
          <li className="c62 li-bullet-2">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Ausfallzeiten aufgrund von Viren- oder Hackerangriffen, soweit
                der Anbieter die vereinbarten, mangels Vereinbarung die
                &uuml;blichen Schutzma&szlig;nahmen getroffen hat;
              </span>
            </h2>
          </li>
          <li className="c62 li-bullet-2">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Ausfallzeiten f&uuml;r das Einspielen von dringend notwendigen
                Security Patches;
              </span>
            </h2>
          </li>
          <li className="c62 li-bullet-1">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Ausfallzeiten, die durch Dritte (nicht dem Provider zurechenbare
                Personen) verursacht werden;
              </span>
            </h2>
          </li>
          <li className="c47 li-bullet-1">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Ausfallzeiten f&uuml;r planm&auml;&szlig;ige Wartungsarbeiten
                und Datensicherungen, wenn diese in der Zeit von 2:00 Uhr bis
                8:00 Uhr (MEZ) durchgef&uuml;hrt werden und dem Partner
                mindestens 7 Tage vor Durchf&uuml;hrung der Arbeiten in Textform
                oder als Dialogmitteilung angek&uuml;ndigt wurden.
              </span>
            </h2>
          </li>
        </ul>
        <ol className="c8 lst-kix_list_8-0" start="10">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Testzeitraum</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Wenn zwischen den Parteien ein Testzeitraum vereinbart wurde, so
                stellt der Anbieter w&auml;hrend einem zuvor festgelegten
                Testzeitraum die Nutzung der Plattform und der Hardware
                unentgeltlich leihweise f&uuml;r den jeweils vereinbarten
                Zeitraum bereit.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                K&uuml;ndigt der Partner nicht innerhalb dieses Testzeitraums
                mit einer Frist von mindestens 4 Tagen zum Ende des
                Testzeitraums, beginnt die vereinbarte Laufzeit f&uuml;r die
                Nutzung der Plattform und der Hardware und es fallen die
                vereinbarten Kosten an.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Sofern der Partner innerhalb des Testzeitraums fristgerecht
                k&uuml;ndigt, fallen die vereinbarten Kosten f&uuml;r die
                Nutzung der Plattform und der Hardware nicht an.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="11">
          <li className="c4 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Laufzeit</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 c31 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Dieser Vertrag beginnt mit dem Abschluss eines Einzelvertrags
                &uuml;ber das Anmeldeformular.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Mindestvertragslaufzeit gegebenenfalls nach Ende des
                Testzeitraums betr&auml;gt 1 Monat.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Vertrag hat die im Anmeldeformular vereinbarte
                urspr&uuml;ngliche Laufzeit und verl&auml;ngert sich um eine
                weitere Laufzeit gleicher Dauer, wenn der Vertrag nicht bis zum
                Ende der Laufzeit gek&uuml;ndigt wird.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die K&uuml;ndigung kann schriftlich, per E-Mail oder im
                Nutzerkonto erkl&auml;rt werden.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Das Recht beider Vertragsparteien, den Vertrag aus wichtigem
                Grund zu k&uuml;ndigen, bleibt von den vorstehenden Regelungen
                unber&uuml;hrt.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="12">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Verg&uuml;tung</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                F&uuml;r die in diesem Vertrag vereinbarte Nutzung der Plattform
                f&uuml;r einen Standort des Partners nach einem etwaig
                vereinbarten Testzeitraum f&auml;llt die im Anmeldeformular
                vereinbarte Verg&uuml;tung an. Die H&ouml;he der Verg&uuml;tung
                richtet sich nach dem monatlichen Brutto-Umsatz des
                ma&szlig;geblichen Standorts des Partners, f&uuml;r den die
                Plattform genutzt werden soll. Die H&ouml;he der Verg&uuml;tung
                wird f&uuml;r jeden Standort einzeln berechnet und abgerechnet.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Verg&uuml;tung ist 5 Tage nach Vertragsschluss oder im Falle
                eines Testzeitraums, 5 Tage nach Ende des Testzeitraums
                f&auml;llig und wird von dem im Anmeldeformular angegebenen
                Zahlungsmittel automatisch abgerechnet. Alle weiteren Zahlungen
                sind jeweils zu Beginn jedes Kalendermonats f&auml;llig und
                werden automatisch abrechnet.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                F&uuml;r die Nutzung der Hardware nach Ziffer 6 f&auml;llt die
                im Anmeldeformular vereinbarte einmalige Geb&uuml;hr an. Diese
                Geb&uuml;hr ist 42 Tage nach Vertragsschluss f&auml;llig und
                wird mit dem erzielten Umsatz verrechnet.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter stellt dem Partner Rechnungen &uuml;ber die zu
                entrichtenden Verg&uuml;tungen aus. Der Anbieter ist berechtigt,
                dem Partner Rechnungen elektronisch zur Verf&uuml;gung zu
                stellen oder per E-Mail zu senden.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Ist der Partner mit einer vertraglichen Zahlung in Verzug, kann
                der Anbieter den Zugang des Partners zur Plattform sperren.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Alle auf der Website und im Anmeldeformular angegebenen Preise
                sind Nettopreise und enthalten nicht die gesetzliche
                Umsatzsteuer.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Der Partner ist verpflichtet &uuml;ber die Plattform f&uuml;r
                die Zwecke der Zahlungsabwicklung zu seinen Endkunden in eine
                vertragliche Beziehung zum Zahlungsdienstleister Stripe, Inc.,
                354 Oyster Point Boulevard, South San Francisco, California,
                94080, USA (im Folgenden: Stripe) zu treten. Dazu legt der
                Partner in seinem Nutzerkonto ein eigenes Konto bei Stripe an.
                Er wird f&uuml;r die Kontoerstellung zu Stripe weitergeleitet.
                Die Kontoerstellung bei Stripe richtet sich nach den Bedingungen
                unter https://stripe.com/de/legal/ssa. Das Konto wird als mit
                der Plattform des Anbieters verbundenes Konto angelegt und kann
                nur im Zusammenhang mit dem Service des Anbieters genutzt
                werden. Der Anbieter kann die verbundenen Konten und die damit
                verkn&uuml;pften Daten auf seinem Plattform-Account einsehen,
                Transaktionen mit den Endkunden der Partner verwalten und
                Einstellungen bez&uuml;glich der geschuldeten Provision des
                Partners vornehmen. Zahlungen von Partnern werden direkt von
                Stripe auf das verbundene Konto des Partners unter Abzug der
                vereinbarten Provision weitergeleitet. Der Anbieter hat so lange
                Zugriff auf das verbundene Konto, bis der Partner durch
                &Auml;nderung der Einstellungen in seinem Konto-Account bei
                Stripe seine Autorisierung ausdr&uuml;cklich zur&uuml;ckzieht
                oder das Konto bei Stripe schlie&szlig;t.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="13">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Haftungsbeschr&auml;nkung</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter haftet im Rahmen dieses Vertrags dem Grunde nach
                nur f&uuml;r Sch&auml;den, (a) die der Anbieter oder seine
                gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen
                vors&auml;tzlich oder grob fahrl&auml;ssig herbeigef&uuml;hrt
                haben bzw. (b) die aus der Verletzung des Lebens, des
                K&ouml;rpers oder der Gesundheit durch eine Pflichtverletzung
                des Anbieters oder einer seiner gesetzlichen Vertreter oder
                Erf&uuml;llungsgehilfen entstanden sind. Der Anbieter haftet
                ferner, (c) wenn der Schaden durch die Verletzung einer
                Verpflichtung des Anbieters entstanden ist, deren Erf&uuml;llung
                die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung dieses Vertrags
                &uuml;berhaupt erst erm&ouml;glicht und auf deren Einhaltung der
                Partner regelm&auml;&szlig;ig vertraut und vertrauen darf
                (Kardinalpflicht).
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter haftet in den F&auml;llen des Absatzes 1 dieser
                Ziffer, Buchstaben (a) und (b) der H&ouml;he nach im Rahmen des
                gesetzlichen Haftungsumfangs. Im &Uuml;brigen ist der
                Schadensersatzanspruch auf den vertragstypisch vorhersehbaren
                Schaden begrenzt. Die Parteien sind sich einig, dass ein Schaden
                maximal in H&ouml;he von 500 EUR pro Schadensfall
                vertragstypisch vorhersehbar ist. Droht dem Partner ein Schaden,
                der diesen Betrag &uuml;berschreiten kann, so ist er
                verpflichtet, den Anbieter unverz&uuml;glich hierauf aufmerksam
                zu machen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                In anderen als den in Absatz 1 genannten F&auml;llen ist die
                Haftung des Anbieters unabh&auml;ngig vom Rechtsgrund
                ausgeschlossen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Haftungsregelungen in vorstehenden Abs&auml;tzen gelten auch
                f&uuml;r eine pers&ouml;nliche Haftung der Organe, Mitarbeiter
                und Erf&uuml;llungsgehilfen des Anbieters.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Soweit eine Haftung nach dem Produkthaftungsgesetz, aus der
                &Uuml;bernahme einer Garantie oder wegen arglistiger
                T&auml;uschung in Betracht kommt, bleibt sie von den
                vorstehenden Haftungsregelungen unber&uuml;hrt.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="14">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Geheimhaltung</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Parteien vereinbaren, &uuml;ber vertrauliche Informationen
                Stillschweigen zu wahren. Vertrauliche Informationen sind alle
                Informationen und Unterlagen der jeweils anderen Partei, die als
                vertraulich gekennzeichnet sind, als Gesch&auml;ftsgeheimnis im
                Sinne des Gesch&auml;ftsgeheimnisgesetz gelten oder die
                angesichts der Art der Informationen und/oder der Umst&auml;nde
                der Offenlegung vern&uuml;nftigerweise als vertraulich anzusehen
                sind und &uuml;ber welche die Parteien im Rahmen der
                vertraglichen Zusammenarbeit Kenntnis erhalten haben,
                insbesondere Informationen &uuml;ber betriebliche Abl&auml;ufe,
                Geschehensabl&auml;ufe, Gesch&auml;ftsbeziehungen, Know-how und
                Interna.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Diese Verpflichtung besteht nach Beendigung dieses Vertrags
                f&uuml;r einen Zeitraum von 5 Jahren fort.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Von dieser Verpflichtung ausgenommen sind solche vertraulichen
                Informationen,
              </span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_list_20-0 start">
          <li className="c57 li-bullet-1">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                die dem Empf&auml;nger bei Abschluss dieses Vertrags
                nachweislich bereits bekannt waren oder danach von dritter Seite
                bekannt werden, ohne dass dadurch eine
                Vertraulichkeitsvereinbarung, gesetzliche Vorschriften oder
                beh&ouml;rdliche Anordnungen verletzt werden;
              </span>
            </h2>
          </li>
          <li className="c62 li-bullet-1">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                die bei Abschluss dieses Vertrags &ouml;ffentlich bekannt sind
                oder danach &ouml;ffentlich bekannt gemacht werden, soweit dies
                nicht auf einer Verletzung dieses Vertrags beruht oder
              </span>
            </h2>
          </li>
          <li className="c47 li-bullet-1">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                die aufgrund gesetzlicher Verpflichtungen oder auf Anordnung
                eines Gerichtes oder einer Beh&ouml;rde offengelegt werden
                m&uuml;ssen; soweit zul&auml;ssig und m&ouml;glich wird der zur
                Offenlegung verpflichtete Empf&auml;nger die andere Partei vorab
                unterrichten und ihr Gelegenheit geben, gegen die Offenlegung
                vorzugehen.
              </span>
            </h2>
          </li>
        </ul>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Parteien werden nur solchen Beratern Zugang zu vertraulichen
                Informationen gew&auml;hren, die dem Berufsgeheimnis unterliegen
                oder denen zuvor den Geheimhaltungsverpflichtungen dieses
                Vertrags entsprechende Verpflichtungen auferlegt worden sind.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Des Weiteren werden die Parteien nur denjenigen Mitarbeitern die
                vertraulichen Informationen offenlegen, die diese f&uuml;r die
                Durchf&uuml;hrung dieses Vertrags kennen m&uuml;ssen, und diese
                Mitarbeiter auch f&uuml;r die Zeit nach ihrem Ausscheiden in
                arbeitsrechtlich zul&auml;ssigem Umfang zur Geheimhaltung
                verpflichten.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="15">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Datenschutz</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Alle personenbezogenen Daten, die eine Partei im Zusammenhang
                mit diesem Vertrag erh&auml;lt oder erhebt, werden in
                &Uuml;bereinstimmung mit den geltenden Datenschutzbestimmungen
                und dem Zweck dieses Vertrags oder wie anderweitig zul&auml;ssig
                verarbeitet.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Der Anbieter verarbeitet durch den Betrieb der Plattform
                personenbezogene Daten im Auftrags des Partners. Teil des
                Vertrags ist die in{" "}
              </span>
              <span className="c26">Annex 1</span>
              <span className="c1">
                &nbsp;enthaltene Vereinbarung zur Auftragsverarbeitung.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-0" start="16">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Referenznennung</span>
            </h1>
          </li>
        </ol>
        <h2 className="c52">
          <span className="c1">
            Der Anbieter ist berechtigt, den Partner inklusive Firmenname und
            Logo zu Referenzzwecken auf der Webseite des Anbieters und in
            Offline-Marketingmaterialien wie Flyern und
            Produktpr&auml;sentationen zu nennen.
          </span>
        </h2>
        <ol className="c8 lst-kix_list_8-0" start="17">
          <li className="c4 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Schlussbestimmungen</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_8-1 start" start="1">
          <li className="c2 c31 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Abweichende, entgegenstehende oder erg&auml;nzende Allgemeine
                Gesch&auml;ftsbedingungen akzeptiert der Anbieter nicht. Dies
                gilt auch, wenn er der Einbeziehung nicht ausdr&uuml;cklich
                widerspricht.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Alle Vertr&auml;ge zwischen dem Anbieter und dem Partner
                k&ouml;nnen nur in deutscher Sprache geschlossen werden. Die
                Vertragstexte werden vom Anbieter nicht gespeichert. Die
                Vertragstexte werden dem Partner einmalig bei Vertragsschluss
                &uuml;bersandt oder vor Vertragsschluss zum Download
                bereitgestellt.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                &Auml;nderungen und Erg&auml;nzungen dieses Vertrags sowie ein
                Verzicht auf ein Recht aus diesem Vertrag bed&uuml;rfen zu ihrer
                Wirksamkeit der Schrift- oder Textform. Dies gilt auch f&uuml;r
                den Verzicht auf das Schrift- oder Textformerfordernis.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Anbieter ist berechtigt, die AGB zu &auml;ndern, soweit die
                &Auml;nderungen f&uuml;r den Partner zumutbar sind.
                &Auml;nderungen der AGB werden dem Partner fr&uuml;hzeitig
                mindestens sechs Wochen vor Geltung der ge&auml;nderten AGB
                schriftlich, per E-Mail oder in sonstiger geeigneter Weise
                bekannt gegeben. Die &Auml;nderungen gelten als genehmigt und
                mit Inkrafttreten f&uuml;r ein bestehendes
                Vertragsverh&auml;ltnis als bindend, wenn der Partner weder
                schriftlich noch per E-Mail innerhalb eines Monats ab Zugang der
                &Auml;nderungsmitteilung Widerspruch erhebt. Auf diese Folge
                wird der Partner bei der &Auml;nderungsmitteilung besonders
                hingewiesen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Es gilt deutsches Recht unter Ausschluss der Vorschriften des
                internationalen Privatrechts sowie des UN-Kaufrechts.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                F&uuml;r alle Streitigkeiten aus oder im Zusammenhang mit dieses
                Vertrag (einschlie&szlig;lich solcher &uuml;ber ihre
                G&uuml;ltigkeit) sind in erster Instanz die Gerichte in
                M&uuml;nchen ausschlie&szlig;lich zust&auml;ndig.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Sollte eine Bestimmung dieses Vertrags ganz oder teilweise
                unwirksam oder undurchf&uuml;hrbar sein oder die Wirksamkeit
                oder Durchf&uuml;hrbarkeit sp&auml;ter verlieren oder sich eine
                Regelungsl&uuml;cke herausstellen, soll hierdurch die
                G&uuml;ltigkeit der &uuml;brigen Bestimmungen nicht ber&uuml;hrt
                werden. Die Parteien verpflichten sich, anstelle der unwirksamen
                oder undurchf&uuml;hrbaren Bestimmung oder zur Ausf&uuml;llung
                der Regelungsl&uuml;cke eine angemessene Regelung zu
                vereinbaren, die, soweit rechtlich zul&auml;ssig, dem am
                n&auml;chsten kommt, was die Parteien gewollt haben oder nach
                dem Sinn und Zweck des Vertrags gewollt h&auml;tten, wenn sie
                die Unwirksamkeit oder die Regelungsl&uuml;cke gekannt
                h&auml;tten. Beruht die Unwirksamkeit oder
                Undurchf&uuml;hrbarkeit einer Bestimmung auf einem darin
                festgelegten Ma&szlig; der Leistung oder der Zeit (Frist oder
                Termin), so ist das der Bestimmung am n&auml;chsten kommende
                rechtlich zul&auml;ssige Ma&szlig; zu vereinbaren. Es ist der
                ausdr&uuml;ckliche Wille der Parteien, dass dieser Absatz keine
                blo&szlig;e Beweislastumkehr zur Folge hat, sondern &sect; 139
                BGB insgesamt abbedungen ist.
              </span>
              <hr style={{ pageBreakBefore: "always", display: "none" }} />
            </h2>
          </li>
        </ol>
        <p className="c3">
          <span className="c10">
            Annex 1 - Vereinbarung &uuml;ber die Auftragsverarbeitung
            gem&auml;&szlig; Art. 28 DSGVO
          </span>
        </p>
        <ol className="c8 lst-kix_list_14-0 start" start="1">
          <li className="c32 c53 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Pr&auml;ambel</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Diese Vereinbarung konkretisiert die datenschutzrechtlichen
                Verpflichtungen der Vertragsparteien, die sich aus der
                Auftragsdatenverarbeitung des Anbieters und Auftragsverarbeiters
                f&uuml;r den Partner und Verantwortlichen auf Grundlage des
                zwischen den Parteien geschlossenen Vertrags zur Nutzung der
                Plattform (im Folgenden: Hauptvertrag) ergeben.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die konkretisierten Verpflichtungen finden Anwendung auf alle
                T&auml;tigkeiten, die mit der Auftragsverarbeitung in
                Zusammenhang stehen und bei denen Mitarbeiter des
                Auftragsverarbeiters oder durch den Auftragsverarbeiter
                beauftragte Dritte mit personenbezogenen Daten des
                Verantwortlichen in Ber&uuml;hrung kommen k&ouml;nnen.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-0" start="2">
          <li className="c0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">
                Gegenstand des Auftrages; Konkretisierung des Auftragsinhalts
                durch Weisungen
              </span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Der Auftragsverarbeiter verarbeitet personenbezogene Daten im
                Auftrag des Verantwortlichen. Art und Zweck dieser
                Datenverarbeitung sowie die Betroffenen dieser Datenverarbeitung
                einschlie&szlig;lich der betroffenen Datenarten ergeben sich aus{" "}
              </span>
              <span className="c26">Anlage 1</span>
              <span className="c1">
                &nbsp;zu dieser Vereinbarung sowie aus dem Hauptvertrag.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die Erbringung der vertraglich vereinbarten Datenverarbeitung
                findet ausschlie&szlig;lich in einem Mitgliedsstaat der
                Europ&auml;ischen Union oder in einem anderen Vertragsstaat des
                Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum statt.
                Jede Verlagerung der Datenverarbeitung in ein Drittland bedarf
                der vorherigen Information des Verantwortlichen und darf nur
                erfolgen, wenn die besonderen Voraussetzungen der Art. 44 ff.
                DSGVO erf&uuml;llt werden.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Verantwortliche ist berechtigt, dem Auftragsverarbeiter
                Weisungen hinsichtlich der Datenverarbeitung zu erteilen.
                Weisungen sind grunds&auml;tzlich in Textform zu erteilen.
                Werden Weisungen ausnahmsweise m&uuml;ndlich erteilt, sind sie
                unverz&uuml;glich nachtr&auml;glich vom Verantwortlichen
                schriftlich in Textform zu dokumentieren. Der
                Auftragsverarbeiter und jede dem Auftragsverarbeiter
                unterstellte Person, die Zugang zu personenbezogenen Daten hat,
                d&uuml;rfen die vertragsgegenst&auml;ndlichen Daten
                ausschlie&szlig;lich entsprechend der Weisung des
                Verantwortlichen verarbeiten einschlie&szlig;lich der in dieser
                Vereinbarung einger&auml;umten Befugnisse, es sei denn, dass sie
                gesetzlich zur Verarbeitung verpflichtet sind. Der
                Auftragsverarbeiter hat den Verantwortlichen unverz&uuml;glich
                zu informieren, wenn er der Meinung ist, eine Weisung
                versto&szlig;e gegen Datenschutzvorschriften. Der
                Auftragsverarbeiter ist berechtigt, die Durchf&uuml;hrung der
                entsprechenden Weisung so lange auszusetzen, bis sie durch den
                Verantwortlichen best&auml;tigt oder ge&auml;ndert wird.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Bestimmt der Auftragsverarbeiter die Zwecke und Mittel der
                Verarbeitung unter Versto&szlig; gegen die DSGVO, ist er
                bez&uuml;glich der in dieser Vereinbarung geregelten
                Verarbeitung selbst Verantwortlicher und haftet entsprechend
                gem&auml;&szlig; Art. 82 DSGVO.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-0" start="3">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Pflichten des Auftragsverarbeiters</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter wird in seinem Verantwortungsbereich die
                innerbetriebliche Organisation so gestalten, dass sie den
                Anforderungen des Datenschutzes gerecht wird. Er wird technische
                und organisatorische Ma&szlig;nahmen zum angemessenen Schutz der
                Daten des Verantwortlichen treffen, die den Anforderungen der
                Datenschutz-Grundverordnung insbesondere Art. 32 DSGVO
                gen&uuml;gen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter wird bei der Durchf&uuml;hrung der
                Arbeiten nur Mitarbeiter einsetzen, die er mit den f&uuml;r sie
                ma&szlig;gebenden Bestimmungen des Datenschutzes vertraut
                gemacht hat und in geeigneter Weise zur Verschwiegenheit
                verpflichtet hat (Art. 28 Abs. 3 Satz 2 lit. b und Art. 29
                DSGVO).
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>
                Der Auftragsverarbeiter hat die Umsetzung der erforderlichen
                technischen und organisatorischen Ma&szlig;nahmen vor Beginn der
                Verarbeitung hinsichtlich der konkreten
                Auftragsdurchf&uuml;hrung in{" "}
              </span>
              <span className="c26">Anlage 2</span>
              <span className="c1">
                &nbsp;dokumentiert. Dem Verantwortlichen sind diese technischen
                und organisatorischen Ma&szlig;nahmen bekannt und er tr&auml;gt
                die Verantwortung daf&uuml;r, dass diese f&uuml;r die Risiken
                der zu verarbeitenden Daten ein angemessenes Schutzniveau
                bieten.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Die technischen und organisatorischen Ma&szlig;nahmen
                unterliegen dem technischen Fortschritt und der
                Weiterentwicklung. Insoweit ist es dem Auftragsverarbeiter
                gestattet, alternative ad&auml;quate Ma&szlig;nahmen umzusetzen.
                Dabei darf das Sicherheitsniveau der festgelegten
                Ma&szlig;nahmen nicht unterschritten werden. Wesentliche
                &Auml;nderungen sind zu dokumentieren.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter wird den Verantwortlichen dabei
                unterst&uuml;tzen, seiner Pflicht zur Beantwortung von
                Antr&auml;gen auf Wahrnehmung von datenschutzrechtlichen Rechten
                Betroffener nachzukommen und wird ihn bei der Einhaltung seiner
                datenschutzrechtlichen Pflichten aus Art. 32-36 DSGVO
                unterst&uuml;tzen und ihm alle erforderlichen Informationen zur
                Einhaltung der in Art. 28 DSGVO niedergelegten Pflichten zur
                Verf&uuml;gung stellen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter wird den Verantwortlichen jederzeit auf
                Anfrage &uuml;ber Person und Kontaktdaten des Ansprechpartners
                in Datenschutzangelegenheiten informieren.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-0" start="4">
          <li className="c0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">
                Kontrollen des Verantwortlichen und von Aufsichtsbeh&ouml;rden
              </span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Sollten im Einzelfall Kontrollen der technischen
                organisatorischen Ma&szlig;nahmen hinsichtlich der
                vertragsgegenst&auml;ndlichen Daten durch den Verantwortlichen
                erforderlich sein, werden diese zu den &uuml;blichen
                Gesch&auml;ftszeiten ohne St&ouml;rung des Betriebsablaufs nach
                vorheriger Anmeldung unter Ber&uuml;cksichtigung einer
                angemessenen Vorlaufzeit durchgef&uuml;hrt.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter darf die Durchf&uuml;hrung von
                Kontrollen von der Unterzeichnung einer
                Verschwiegenheitserkl&auml;rung hinsichtlich der Daten anderer
                Kunden und der eingerichteten technischen und organisatorischen
                Ma&szlig;nahmen abh&auml;ngig machen, wenn nicht der
                Verantwortliche einen von Gesetzes wegen und/oder
                berufsrechtlich zur Verschwiegenheit verpflichteten Pr&uuml;fer
                mit der Durchf&uuml;hrung der Kontrolle beauftragt.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Sollte der durch den Verantwortlichen beauftragte Pr&uuml;fer in
                einem Wettbewerbsverh&auml;ltnis zum Auftragsverarbeiter stehen,
                hat der Auftragsverarbeiter gegen diesen ein Einspruchsrecht.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Sollte eine Datenschutzaufsichtsbeh&ouml;rde oder eine sonstige
                hoheitliche Aufsichtsbeh&ouml;rde des Verantwortlichen eine
                Kontrolle der vertragsgegenst&auml;ndlichen Datenverarbeitung
                durchf&uuml;hren wollen, wird der Auftragsverarbeiter den
                Verantwortlichen hierbei unterst&uuml;tzen. Die vorstehenden
                Abs&auml;tze gelten entsprechend.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-0" start="5">
          <li className="c0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">
                Berichtigung, Einschr&auml;nkung und L&ouml;schung von Daten
              </span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter darf die Daten, die im Auftrag
                verarbeitet werden, nur l&ouml;schen oder deren Verarbeitung
                einschr&auml;nken, wenn dies im Hauptvertrag oder in dieser
                Vereinbarung &uuml;ber die Auftragsverarbeitung vorgesehen ist
                oder der Verantwortliche eine entsprechende Weisung erteilt.
                Soweit eine betroffene Person sich mit einem L&ouml;schbegehren
                unmittelbar an den Auftragsverarbeiter wendet, wird der
                Auftragsverarbeiter dieses Ersuchen unverz&uuml;glich an den
                Verantwortlichen weiterleiten.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter wird nach Ende dieser Vereinbarung alle
                vertragsgegenst&auml;ndlichen personenbezogenen Daten nach Wahl
                des Verantwortlichen entweder l&ouml;schen oder
                zur&uuml;ckgeben, soweit nicht nach dem Unionsrecht oder dem
                deutschen Datenschutzrecht eine Verpflichtung zur Speicherung
                der personenbezogenen Daten besteht.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Kopien oder Duplikate der Daten werden ohne Wissen des
                Verantwortlichen nicht erstellt. Hiervon ausgenommen sind
                Sicherheitskopien, soweit sie zur Gew&auml;hrleistung einer
                ordnungsgem&auml;&szlig;en Datenverarbeitung erforderlich sind,
                sowie Daten, die im Hinblick auf die Einhaltung gesetzlicher
                Aufbewahrungspflichten erforderlich sind.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-0" start="6">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Unterauftragsverh&auml;ltnisse</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-1 start" start="1">
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Als Unterauftragsverh&auml;ltnisse im Sinne dieser Regelung sind
                solche Dienstleistungen zu verstehen, die sich unmittelbar auf
                die Erbringung der Hauptleistung beziehen. Nicht hierzu
                geh&ouml;ren Nebenleistungen, die der Auftragsverarbeiter z.B.
                als Telekommunikationsleistungen,
                Post-/Transportdienstleistungen, Wartung und Benutzerservice,
                Reinigungskr&auml;fte, Pr&uuml;fer oder die Entsorgung von
                Datentr&auml;gern sowie sonstige Ma&szlig;nahmen zur
                Sicherstellung der Vertraulichkeit, Verf&uuml;gbarkeit,
                Integrit&auml;t und Belastbarkeit der Hard- und Software von
                Datenverarbeitungsanlagen in Anspruch nimmt. Der
                Auftragsverarbeiter ist jedoch verpflichtet, zur
                Gew&auml;hrleistung des Datenschutzes und der Datensicherheit
                der Daten des Verantwortlichen auch bei ausgelagerten
                Nebenleistungen angemessene und gesetzeskonforme vertragliche
                Vereinbarungen sowie Kontrollma&szlig;nahmen zu ergreifen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Der Auftragsverarbeiter darf Unterauftragnehmer (weitere
                Auftragsverarbeiter) nur nach vorheriger ausdr&uuml;cklicher
                schriftlicher bzw. dokumentierter Zustimmung des
                Verantwortlichen oder nach einer Information des
                Verantwortlichen, die den Anforderungen von Art. 28 Abs. 2 S. 2
                DSGVO entspricht, beauftragen.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Eine Beauftragung von Unterauftragnehmern in Drittl&auml;ndern
                darf nur erfolgen, wenn zus&auml;tzlich die besonderen
                Voraussetzungen der Art. 44 ff. DSGVO erf&uuml;llt sind.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span>Der Verantwortliche stimmt den in </span>
              <span className="c26">Anlage 3</span>
              <span className="c1">
                &nbsp;genannten Unterauftragnehmern mit Vertragsschluss zu.
              </span>
            </h2>
          </li>
          <li className="c2 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Verweigert der Verantwortliche die Zustimmung oder widerspricht
                er der Beauftragung des Unterauftragnehmers, so ist der
                Auftragsverarbeiter berechtigt, den Hauptvertrag vorzeitig mit
                einer Frist von zwei Wochen zu k&uuml;ndigen.
              </span>
            </h2>
          </li>
        </ol>
        <ol className="c8 lst-kix_list_14-0" start="7">
          <li className="c17 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Laufzeit</span>
            </h1>
          </li>
        </ol>
        <h2 className="c52">
          <span>
            Diese Vereinbarung gilt in dieser Form ab Abschluss des
            Hauptvertrags und endet mit der vollst&auml;ndigen Durchf&uuml;hrung
            der in{" "}
          </span>
          <span className="c26">Anlage 1</span>
          <span className="c1">
            &nbsp;beschriebenen Ma&szlig;nahmen, ohne dass es einer
            K&uuml;ndigung durch eine der Parteien bed&uuml;rfte oder mit der
            Beendigung des dieser Vereinbarung zugrundeliegenden Hauptvertrags.
          </span>
        </h2>
        <hr style={{ pageBreakBefore: "always", display: "none" }} />
        <p className="c3 c16">
          <span className="c1"></span>
        </p>
        <p className="c3">
          <span className="c19">
            Anlage 1 - Auflistung der beauftragten Dienstleistungen (Umfang,
            Art, Zweck der Erhebung, Verarbeitung, Nutzung von Daten, Art der
            Daten, Kreis der Betroffenen)
          </span>
        </p>
        <a id="t.8b9062253857476b840ce300b1aebf5a1ee49a7b"></a>
        <a id="t.1"></a>
        <table className="c59">
          <tr className="c6">
            <td className="c39" colSpan="1" rowSpan="1">
              <p className="c30">
                <span className="c10">Art der Leistung</span>
              </p>
            </td>
            <td className="c28" colSpan="1" rowSpan="1">
              <p className="c30">
                <span className="c10">Art der Verarbeitung</span>
              </p>
            </td>
            <td className="c69" colSpan="1" rowSpan="1">
              <p className="c30">
                <span className="c10">Datenarten</span>
              </p>
            </td>
            <td className="c56" colSpan="1" rowSpan="1">
              <p className="c30">
                <span className="c35 c34">Kreis der Betroffenen</span>
                <sup>
                  <a href="#cmnt1" id="cmnt_ref1">
                    [a]
                  </a>
                </sup>
              </p>
            </td>
          </tr>
          <tr className="c6">
            <td className="c39" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c1">
                  Betrieb einer Softwareplattform zur Bereitstellung von
                  Internetauftritten und Infrastruktur f&uuml;r den Vertrieb von
                  Produkten f&uuml;r Restaurants, Gastst&auml;tten, Bistros,
                  Caf&eacute;s und &Auml;hnlichem;
                </span>
              </p>
              <p className="c12">
                <span className="c1">
                  Weiterleitung von und Bereitstellung eines technischen Rahmens
                  f&uuml;r Bestellungen von Endkunden an den Verantwortlichen
                </span>
              </p>
            </td>
            <td className="c28" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c1">
                  Daten von Kunden werden &uuml;ber Schnittstellen auf Server
                  des Auftragsverarbeiters importiert und dort erfasst,
                  gespeichert und verarbeitet;
                </span>
              </p>
              <p className="c12">
                <span className="c1">
                  der Verantwortliche kann diese Daten &uuml;ber die
                  bereitgestellte Software einsehen;
                </span>
              </p>
              <p className="c12">
                <span className="c1">
                  Daten des Verantwortlichen werden zum Zwecke der Leistung
                  erfasst, auf dem Server des Auftragsverarbeiters gespeichert
                  und verarbeitet
                </span>
              </p>
            </td>
            <td className="c69" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c1">
                  Vor- und Nachnamen, Kontaktdaten (z.B. Adresse, E-Mail,
                  Telefonnummer), Bestellhistorie, Zahlungsdaten,
                  Nachrichteninhalte von Freitextfeldern
                </span>
              </p>
            </td>
            <td className="c56" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c1">
                  Kunden des Verantwortlichen, Lieferanten des Verantwortlichen,
                  Mitarbeiter des Verantwortlichen, Website-Nutzer
                </span>
              </p>
            </td>
          </tr>
        </table>
        <hr style={{ pageBreakBefore: "always", display: "none" }} />
        <p className="c3">
          <span className="c34 c35">
            Anlage 2 &ndash; Technische und organisatorische Ma&szlig;nahmen
          </span>
          <sup>
            <a href="#cmnt2" id="cmnt_ref2">
              [b]
            </a>
          </sup>
        </p>
        <p className="c3 c16">
          <span className="c1"></span>
        </p>
        <ol className="c8 lst-kix_m6920b9r5jdo-0 start" start="1">
          <li className="c14 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Vertraulichkeit</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c10">Zutrittskontrolle:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0 start">
          <li className="c24 li-bullet-1">
            <span className="c1">
              Zutritt zum Geb&auml;ude nur mit Schl&uuml;ssel; Besucher
              m&uuml;ssen klingeln
            </span>
          </li>
          <li className="c9 li-bullet-2">
            <span className="c1">
              Server befinden sich bei einem Subunternehmer (Hoster), mit dem
              ein Vertrag &uuml;ber die Auftragsverarbeitung besteht
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c10">Zugangskontrolle:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c24 li-bullet-2">
            <span className="c1">
              Passwortrichtlinie f&uuml;r alle Passw&ouml;rter (mindestens 8
              Zeichen, mindestens 1 Gro&szlig;buchstabe; mindestens 1 Zahl);
              Richtlinie zum sicheren, ordnungsgem&auml;&szlig;en Umgang mit
              Passw&ouml;rtern
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Ausschlie&szlig;lich pers&ouml;nliche elektronische &Uuml;bergabe
              aller relevanten Passw&ouml;rter durch IT-Leitung (Admin) an
              verantwortliche Mitarbeiter
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Revisionssicheres verbindliches Verfahren zur R&uuml;cksetzung
              &bdquo;vergessener&ldquo; Passw&ouml;rter
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Benutzeridentifikation durch pers&ouml;nlichen Login; Eindeutige
              Zuordnung von Benutzerkonten zu Benutzern
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Je nach Anzahl erfolgloser Anmeldeversuche zeitlich ansteigende
              Sperrung der Anmeldung mit einem Benutzernamen/Passwort
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Automatisierte Standardroutinen f&uuml;r regelm&auml;&szlig;ige
              Aktualisierung von Schutzsoftware (z. B. Virenscanner, Firewall)
            </span>
          </li>
          <li className="c9 li-bullet-2">
            <span className="c1">
              Alle Netzwerklaufwerke sind verschl&uuml;sselt
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c34">Zugriffskontrolle</span>
              <span className="c1">:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c24 li-bullet-1">
            <span className="c1">
              Authentifizierung auf Betriebssystemebene erforderlich
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Separate Authentifizierung und Berechtigungsvergabe auf
              Anwendungsebene
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Berechtigungskonzept nach dem Need-To-Know-Prinzip
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Protokollierung von &Auml;nderungen der Zugriffsberechtigungen
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Protokollierung der Zugriffe durch Logfiles
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Verbot der Verwendung von privaten Speichermedien in der
              IT-Umgebung
            </span>
          </li>
          <li className="c9 li-bullet-1">
            <span className="c1">
              Wiederherstellung von Daten aus Backups nur durch Mitarbeiter im
              IT-Team
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c10">Trennungskontrolle:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c3 c42 li-bullet-1">
            <span className="c1">
              getrennte Verarbeitung von Daten der Kunden
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c10">Pseudonymisierung:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c3 c42 li-bullet-1">
            <span className="c35 c38">
              Pseudonymisierung der Daten z.B. Unkenntlichmachung aller
              personenbezogenen Daten und Informationen, die nicht zur
              Leistungserbringung ben&ouml;tigt werden
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-0" start="2">
          <li className="c14 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">Integrit&auml;t des Systems</span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">Weitergabekontrolle:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c24 li-bullet-1">
            <span className="c1">
              Verschl&uuml;sselung von Daten auf dem Webportal &uuml;ber https
              /SSL
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Protokollierung der &Uuml;bertragungsvorg&auml;nge
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Protokollierung von Daten&uuml;bertragungen durch Logfiles
            </span>
          </li>
          <li className="c9 li-bullet-1">
            <span className="c1">
              regelm&auml;&szlig;iger Penetration-Test der exponierten (von
              au&szlig;en erreichbaren) Systeme
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">Eingabekontrolle:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c24 li-bullet-1">
            <span className="c1">Logfiles (mindestens 30 Tage)</span>
          </li>
          <li className="c9 li-bullet-1">
            <span className="c35 c38">
              anlassbezogen (Troubleshooting) durch die Systemadministratoren
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-0" start="3">
          <li className="c14 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">
                Verf&uuml;gbarkeit und Belastbarkeit des Systems
              </span>
            </h1>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c24 li-bullet-1">
            <span className="c1">
              Dokumentiertes Backup-Konzept und Recovery-Konzept mit
              t&auml;glicher Sicherung
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Festplatten-Echtzeitspiegelung auf redundantem Server in einem
              separaten Rechenzentrum
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Notstrom &uuml;ber Dieselaggregat f&uuml;r Server verf&uuml;gbar
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Sicherheit: Sicherheits-Update f&uuml;r Betriebssystem und
              f&uuml;r Programme wird regelm&auml;&szlig;ig aufgespielt
            </span>
          </li>
          <li className="c9 li-bullet-1">
            <span className="c35 c38">
              Sachkundiger Einsatz von IT-Security (Virenschutz;
              Verschl&uuml;sselung; Spam-Filter; Firewalls etc.) und
              schriftliches Konzept des Einsatzes
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-0" start="4">
          <li className="c14 li-bullet-0">
            <h1 style={{ display: "inline" }}>
              <span className="c10">
                Verfahren zur regelm&auml;&szlig;igen &Uuml;berpr&uuml;fung,
                Bewertung und Evaluierung
              </span>
            </h1>
          </li>
        </ol>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">Datenschutz-Management:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c3 c42 li-bullet-2">
            <span className="c1">Schulungen der Mitarbeiter</span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 style={{ display: "inline" }}>
              <span className="c1">
                Datenschutzfreundliche Voreinstellungen:
              </span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_8h3ngux134lm-0 start">
          <li className="c3 c42 li-bullet-1">
            <span className="c1">
              System standardm&auml;&szlig;ig so konfiguriert, dass alle
              personenbezogenen Daten, die nichtmehr ben&ouml;tigt werden und
              &auml;lter als 180 Tage sind, automatisch gel&ouml;scht werden
            </span>
          </li>
        </ul>
        <ol className="c8 lst-kix_m6920b9r5jdo-1 start" start="1">
          <li className="c3 c32 li-bullet-0">
            <h2 id="h.xh88lbe3pvw5" style={{ display: "inline" }}>
              <span className="c1">Auftragskontrolle:</span>
            </h2>
          </li>
        </ol>
        <ul className="c8 lst-kix_6heel87l2wmr-0">
          <li className="c24 li-bullet-1">
            <span className="c1">
              Keine Datenverarbeitung im Sinne von Art. 28 DSGVO ohne
              entsprechende Weisung des Verantwortlichen
            </span>
          </li>
          <li className="c15 li-bullet-1">
            <span className="c1">
              Eindeutige Vertragsgestaltung gem&auml;&szlig; Art. 28 DSGVO
            </span>
          </li>
        </ul>
        <p className="c3">
          <span className="c35 c38">
            Strenge Auswahl von Subunternehmern; Beauftragung nur durch Vertrag
            &uuml;ber die Auftragsverarbeitung gem&auml;&szlig; Art. 28 DSGVO
          </span>
          <hr style={{ pageBreakBefore: "always", display: "none" }} />
        </p>
        <p className="c3">
          <span className="c19">Anlage 3 &ndash; Unterauftragnehmer</span>
          <sup>
            <a href="#cmnt3" id="cmnt_ref3">
              [c]
            </a>
          </sup>
        </p>
        <p className="c3 c16" id="h.fscn7ttjibut">
          <span className="c1"></span>
        </p>
        <p className="c3 c16">
          <span className="c25 c38 c45"></span>
        </p>
        <a id="t.5b52f76f3fde6e352d985274ddb8090fde668f7e"></a>
        <a id="t.2"></a>
        <table className="c60">
          <tr className="c6">
            <td className="c55" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c10">Unterauftragnehmer</span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c10">Adresse</span>
              </p>
            </td>
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c10">Zweck der Beauftragung</span>
              </p>
            </td>
            <td className="c13" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c10">Ort der Verarbeitung</span>
              </p>
            </td>
          </tr>
          <tr className="c6">
            <td className="c55" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">Amazon Web Services EMEA SARL</span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">
                  38 Avenue John F. Kennedy, L-1855 Luxembourg
                </span>
              </p>
            </td>
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">Hoster</span>
              </p>
            </td>
            <td className="c13" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">EU</span>
              </p>
            </td>
          </tr>
          <tr className="c6">
            <td className="c55" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">
                  Stripe Payments Europe, Limited (SPEL)
                </span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">
                  1 Grand Canal Street Lower, Grand Canal Dock, Dublin, D02
                  H210, Irland
                </span>
              </p>
            </td>
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">
                  Subscription Management, Zahlungsdienstleister
                </span>
              </p>
            </td>
            <td className="c13" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">EU</span>
              </p>
            </td>
          </tr>
          <tr className="c6">
            <td className="c55" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c48">Google </span>
              </p>
            </td>
            <td className="c23" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c11">Clanwilliam Place Dublin 2 Ireland</span>
              </p>
            </td>
            <td className="c18" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c48">
                  E-Mail Kommunikation, Analyse, Customer Management, Google
                  Maps
                </span>
              </p>
            </td>
            <td className="c13" colSpan="1" rowSpan="1">
              <p className="c12">
                <span className="c48">EU</span>
              </p>
            </td>
          </tr>
        </table>
        <p className="c3 c16">
          <span className="c11"></span>
        </p>
        <p className="c3">
          <span className="c41">
            Die &Uuml;bermittlung der Daten in die USA erfolgt auf Grundlage des
            am 10.07.2023 erlassenen Angemessenheitsbeschlusses der
            Europ&auml;ischen Kommission (Angemessenheitsbeschluss f&uuml;r das
            EU-US Data Privacy Framework) gem&auml;&szlig; Art. 45
          </span>
          <span className="c72">&nbsp;D</span>
        </p>
      </div>
      <Footer />
    </AnimationRevealPage>
  );
};
