import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {createGlobalStyle} from "styled-components";
import Footer from "components/footers/Footer.js";

const Style = createGlobalStyle`
  ul.lst-kix_2skaq8gcne7c-1 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-0 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-3 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-2 {
        list-style-type: none
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt
    }

    .lst-kix_2skaq8gcne7c-3 > li:before {
        content: "\\0025cf  "
    }

    .lst-kix_2skaq8gcne7c-2 > li:before {
        content: "\\0025a0  "
    }

    .lst-kix_2skaq8gcne7c-4 > li:before {
        content: "\\0025cb  "
    }

    .lst-kix_2skaq8gcne7c-1 > li:before {
        content: "\\0025cb  "
    }

    .lst-kix_2skaq8gcne7c-5 > li:before {
        content: "\\0025a0  "
    }

    ul.lst-kix_2skaq8gcne7c-5 {
        list-style-type: none
    }

    .lst-kix_2skaq8gcne7c-7 > li:before {
        content: "\\0025cb  "
    }

    ul.lst-kix_2skaq8gcne7c-4 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-7 {
        list-style-type: none
    }

    .lst-kix_2skaq8gcne7c-8 > li:before {
        content: "\\0025a0  "
    }

    .lst-kix_2skaq8gcne7c-0 > li:before {
        content: "\\0025cf  "
    }

    .lst-kix_2skaq8gcne7c-6 > li:before {
        content: "\\0025cf  "
    }

    ul.lst-kix_2skaq8gcne7c-6 {
        list-style-type: none
    }

    ul.lst-kix_2skaq8gcne7c-8 {
        list-style-type: none
    }

    ol {
        margin: 0;
        padding: 0
    }

    table td, table th {
        padding: 0
    }

    .c3 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 93pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c8 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 57pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c7 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 52.5pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c0 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 57.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c4 {
        border-right-style: solid;
        padding: 8pt 12pt 8pt 0pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        width: 195.8pt;
        border-top-color: #000000;
        border-bottom-style: solid
    }

    .c1 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10.5pt;
        font-family: "Helvetica Neue";
        font-style: normal
    }

    .c11 {
        background-color: #ffffff;
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        font-weight: 400;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        font-size: 10.5pt;
        font-family: "Helvetica Neue"
    }

    .c21 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 18pt;
        font-family: "Helvetica Neue";
        font-style: normal
    }

    .c12 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt
    }

    .c27 {
        padding-top: 0pt;
        padding-bottom: 36pt;
        line-height: 1.1;
        orphans: 2;
        widows: 2;
        text-align: center
    }

    .c13 {
        padding-top: 0pt;
        padding-bottom: 8pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c16 {
        padding-top: 15pt;
        padding-bottom: 8pt;
        line-height: 1.1;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c6 {
        padding-top: 12pt;
        padding-bottom: 15pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .c15 {
        background-color: #ffffff;
        font-size: 10.5pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 400
    }

    .c5 {
        background-color: #ffffff;
        font-size: 9pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 700
    }

    .c25 {
        background-color: #ffffff;
        color: #333333;
        font-weight: 700;
        font-size: 24pt;
        font-family: "Helvetica Neue"
    }

    .c2 {
        background-color: #ffffff;
        font-size: 9pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 400
    }

    .c17 {
        background-color: #ffffff;
        font-size: 10.5pt;
        font-family: "Helvetica Neue";
        color: #333333;
        font-weight: 700
    }

    .c31 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto
    }

    .c26 {
        color: #000000;
        font-weight: 400;
        font-size: 11pt;
        font-family: "Arial"
    }

    .c9 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt
    }

    .c28 {
        padding: 0;
        margin: 0
    }

    .c22 {
        vertical-align: baseline;
        font-style: normal
    }

    .c18 {
        color: inherit;
        text-decoration: inherit
    }

    .c24 {
        margin-left: 36pt;
        padding-left: 0pt
    }

    .c20 {
        height: 36pt
    }

    .c14 {
        height: 55.5pt
    }

    .c30 {
        height: 26.2pt
    }

    .c10 {
        height: 45.8pt
    }

    .c23 {
        text-decoration: none
    }

    .c19 {
        height: 66pt
    }

    .c29 {
        height: 75.8pt
    }

    .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial"
    }

    h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left
    }

    h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left
    }
`;
export default () => {
    return (
        <AnimationRevealPage>
            <Style/>
            <div className="p-4">
                <h1 className="c27" id="h.xsled2qv7pd"><span
                    className="c22 c23 c25">Verwendung von Cookies auf der Website</span></h1>
                <p className="c13"><span className="c1">Cookies sind kleine Textdateien, die Informationen &uuml;ber das von einem Besucher verwendete Ger&auml;t liefern. Wenn Cookies verwendet werden, werden sie auf Ihr Ger&auml;t heruntergeladen und auf Ihrem Ger&auml;t gespeichert. Die folgende Tabelle erkl&auml;rt, welche Cookies diese Website verwendet und warum</span>
                </p><a id="t.bbe244e66efb21dba430229a4ab040631eb0f9b6"></a><a id="t.0"></a>
                <table className="c31">
                    <tr className="c30">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span className="c5">Cookie</span>
                        </p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span className="c5">Name</span>
                        </p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c5">Zweck</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span className="c5">Dauer</span>
                        </p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c5">Kategorie</span></p></td>
                    </tr>
                    <tr className="c29">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Stripe</span>
                        </p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2 c22 c23">__stripe_mid</span></p>
                            <p className="c6"><span className="c2 c22 c23">__stripe_orig_props</span></p>
                            <p className="c6"><span className="c2">__stripe_sid</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Stripe wird f&uuml;r Kartenzahlungen verwendet. Stripe verwendet ein Cookie, um sich zu merken, wer Sie sind, und um es der Website zu erm&ouml;glichen, Zahlungen zu verarbeiten, ohne Bezahlkarteninformationen auf ihren eigenen Servern zu speichern.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">1 Jahr</span>
                        </p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmgios</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigosauth</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, um die Information zu speichern, dass der Benutzer sich angemeldet hat.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">6 Monate</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Funktional</span></p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">cookieconsent</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, um Ihre Einstellungen in Bezug auf Cookies zu speichern.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">6 Monate</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigosauth</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, um sich zu erinnern, dass der Benutzer sich angemeldet hat.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Sessi&oacute;</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-token</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird zur Hilfe bei der Authentifizierung auf bestimmten Browsern verwendet</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">6 Monate</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Funktional</span></p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-token</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird zur Hilfe bei der Authentifizierung auf bestimmten Browsern verwendet</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Sessi&oacute;</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c20">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">$fa.wo.b[*]</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet um sich an die Artikel in Ihrem Warenkorb zu erinnern.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c20">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">logLevel:*</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird f&uuml;r Fehlerbeseitigungszwecke verwendet.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c14">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmgios</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-delivery-locations</span></p>
                        </td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Diese wesentlichen Informationen beziehen sich auf Ihre Lieferorte und werden im Browser gespeichert, um eine unn&ouml;tige Datennutzung zu vermeiden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c14">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-customer</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Diese wesentlichen Informationen beziehen sich auf Ihre Kundendaten und werden im Browser gespeichert, um eine unn&ouml;tige Datennutzung zu vermeiden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c14">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-pickup-restaurant-*</span></p>
                        </td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Diese wesentlichen Informationen beziehen sich auf die Details des Gesch&auml;fts und werden im Browser gespeichert, um unn&ouml;tige Datennutzung zu vermeiden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c19">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-location</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Diese wesentlichen Informationen beziehen sich darauf wo in der Welt Ihr Standort ist, so dass relevante Standorte zuerst angezeigt werden, und werden im Browser gespeichert, um unn&ouml;tige Datennutzung zu vermeiden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">$fd.wo.b.om</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, wenn Sie bei der Zahlung zu 3D-Sicherheitszwecken umgeleitet werden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Sessi&oacute;</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c14">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-current-language</span></p>
                        </td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Diese wesentlichen Informationen beziehen sich auf Ihre Spracheinstellungen und werden im Browser gespeichert, um unn&ouml;tige Datenverwendung zu vermeiden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c20">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-test-key</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird f&uuml;r Fehlerbeseitigungs- und Testzwecke verwendet</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c14">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-app-config</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Diese wesentlichen Informationen beziehen sich auf die Details des Gesch&auml;fts und werden im Browser gespeichert, um eine unn&ouml;tige Datennutzung zu vermeiden.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c29">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-last-url</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet um sich daran zu erinnern, in welchem Schritt des Bestellvorgangs Sie sich befanden, als Sie die Seite verlassen haben, so dass Sie bei Ihrer R&uuml;ckkehr dort weitermachen k&ouml;nnen, wo Sie aufgeh&ouml;rt haben.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c20">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-last-url-expiry</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, um die letzte url ablaufen zu lassen</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c14">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">$fd.wo.pa</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, um Benutzerzahlungen zwischenzuspeichern. Diese Informationen werden bei der Abmeldung gel&ouml;scht.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Sessi&oacute;</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-device-id</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird zu Protokollierungs-, Leistungs- und Fehlerbeseitigungszwecken verwendet.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                    <tr className="c10">
                        <td className="c0" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">FoodAmigos</span></p></td>
                        <td className="c3" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">foodamigos-redirect</span></p></td>
                        <td className="c4" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Dieses Cookie wird verwendet, um Ihre Auswahl zu verarbeiten, ob Sie auf die mobile App weitergeleitet werden wollen oder nicht.</span>
                        </p></td>
                        <td className="c7" colSpan="1" rowSpan="1"><p className="c6"><span
                            className="c2">Browser-gesteuert</span></p></td>
                        <td className="c8" colSpan="1" rowSpan="1"><p className="c6"><span className="c2">Unbedingt notwendig</span>
                        </p></td>
                    </tr>
                </table>
                <p className="c13"><span
                    className="c1">Wenn Sie alle nicht unbedingt erforderlichen Cookies ablehnen, k&ouml;nnen Sie die Vorz&uuml;ge der foodamigos-Website m&ouml;glicherweise nicht in vollem Umfang nutzen.</span>
                </p>
                <h2 className="c16" id="h.1je9qhgd13ib"><span
                    className="c21">Wie kann ich meine Cookie-Einstellungen &auml;ndern?</span></h2>
                <p className="c13"><span className="c1">Sie k&ouml;nnen Ihre Cookie-Einstellungen jederzeit &uuml;ber die Cookie-Einstellungen &auml;ndern. Sie k&ouml;nnen dann die Schieberegler auf &quot;Ein&quot; oder &quot;Aus&quot; stellen und dann auf &quot;Fertig&quot; klicken. M&ouml;glicherweise m&uuml;ssen Sie Ihre Seite aktualisieren, damit die Einstellungen wirksam werden.</span>
                </p>
                <p className="c13"><span className="c1">Sie k&ouml;nnen Cookies jederzeit l&ouml;schen oder konfigurieren, indem Sie Ihren Internet-Browser konfigurieren. Bitte besuchen Sie die speziellen Hilfeabschnitte Ihres Browsers, um Informationen &uuml;ber die Cookie-Einstellungen zu erhalten:</span>
                </p>
                <ul className="c28 lst-kix_2skaq8gcne7c-0 start">
                    <li className="c13 c24 li-bullet-0"><span className="c11"><a className="c18"
                                                                                 href="https://www.google.com/url?q=https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1656412763502254&amp;usg=AOvVaw0T3MnCXmCN5jPWQEmQpE36">Internet Explorer</a></span>
                    </li>
                    <li className="c13 c24 li-bullet-0"><span className="c11"><a className="c18"
                                                                                 href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?hl%3Den%26ref_topic%3D14666&amp;sa=D&amp;source=editors&amp;ust=1656412763502722&amp;usg=AOvVaw262qBtwYxWjMG2uDJeRJya">Chrom</a></span>
                    </li>
                    <li className="c13 c24 li-bullet-0"><span className="c11"><a className="c18"
                                                                                 href="https://www.google.com/url?q=https://support.mozilla.com/en-US/kb/cookies-information-websites-store-on-your-computer&amp;sa=D&amp;source=editors&amp;ust=1656412763503162&amp;usg=AOvVaw1n7Jh3xCtf-dlKfCgATU8H">Firefox</a></span>
                    </li>
                    <li className="c13 c24 li-bullet-0"><span className="c11"><a className="c18"
                                                                                 href="https://www.google.com/url?q=https://support.apple.com/en-ie/guide/safari/manage-cookies-and-website-data-sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1656412763503560&amp;usg=AOvVaw3VR2XBPeNY-To7f_y__pke">Safari web</a></span><span
                        className="c15">&nbsp;und </span><span className="c11"><a className="c18"
                                                                                  href="https://www.google.com/url?q=https://support.apple.com/en-ie/HT201265&amp;sa=D&amp;source=editors&amp;ust=1656412763503840&amp;usg=AOvVaw0eYwTsIGCfmSwCSjJrITTE">Safari f&uuml;r iOS</a></span>
                    </li>
                </ul>
                <p className="c13"><span className="c1">Um Informationen zu anderen Browsern zu finden, besuchen Sie die Website des Browser-Entwicklers.</span>
                </p>
                <h2 className="c16" id="h.3fvl40ejn6uh"><span className="c21">Angaben zum Verantwortlichen</span></h2>
                <p className="c13"><span className="c15">Der Verantwortliche ist </span><span className="c17">Foodamigos GmbH </span><span
                    className="c15">(&quot;</span><span className="c17">wir</span><span
                    className="c15">&quot;, &quot;</span><span className="c17">uns</span><span
                    className="c15">&quot; oder &quot;</span><span className="c17">unser</span><span
                    className="c1">&quot;). Wir k&ouml;nnen &uuml;ber die auf unserer Website angegebenen Kontaktdaten kontaktiert werden.</span>
                </p>
                <p className="c12"><span className="c22 c23 c26"></span></p>
            </div>
            <Footer/>
        </AnimationRevealPage>
    );
};
