import Hero from "./hero/HeroIndex";
import Blogs from "./blogs/BlogsIndex";
import Features from "./features/FeaturesIndex";
import Footers from "./footers/FootersIndex";
import Headers from "./headers/HeadersIndex";
import Cta from "./cta/CtaIndex";
import Forms from "./forms/FormsIndex";
import Pricing from "./pricing";

export default { Hero, Blogs, Features, Footers, Headers, Cta, Forms, Pricing };
