import { InputNumber, Select, Slider, Tabs } from "antd";
import collect from "collect.js";
import React, { useLayoutEffect, useMemo, useState } from "react";
import tw from "twin.macro";
import { Chart } from "react-charts";
import { useTranslation } from "react-i18next";
import LocalizationManager from "services/localization/LocalizationManager";
import useWindowSize from "hooks/useWindowSize";
import IncrementalInput from "components/inputs/IncrementalInput";

const RoiCalculator = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "Components:Pricing:RoiCalculator",
    lang: LocalizationManager.getLanguageFromUrl(),
  });

  const windowSize = useWindowSize();

  const chartRef = () => document.getElementById("chart");

  const TABS = {
    FOODAMIGOS_DRIVERS: "foodamigos_drivers",
    OWN_DRIVERS: "own_drivers",
  };

  const SALES = collect([500, 1500, 3000, 6000, 10000]);
  const [tabKey, setTabKey] = useState(TABS.OWN_DRIVERS);
  const [sales, setSales] = useState(6000);
  const [deliveryRatio, setDeliveryRatio] = useState(65);
  const [averageOrder, setAverageOrder] = useState(32);
  const [lieferandoCommissionConfig, setLieferandoCommissionConfig] = useState({
    [TABS.OWN_DRIVERS]: "16",
    [TABS.FOODAMIGOS_DRIVERS]: "30",
  });
  const [lieferandoServiceFee, setLieferandoServiceFee] = useState("0.69");

  const [chartLabelData, setChartLabelData] = useState([]);

  const deliveryRatioPercentage = deliveryRatio / 100;

  const DURATIONS = {
    MONTHLY: "monthly",
    YEARLY: "yearly",
  };

  const durationOptions = [
    {
      label: t("duration.monthly"),
      value: DURATIONS.MONTHLY,
    },
    {
      label: t("duration.yearly"),
      value: DURATIONS.YEARLY,
    },
  ];

  const [duration, setDuration] = useState(DURATIONS.MONTHLY);

  function getAmountPerDuration(num) {
    return (
      Math.round(num * (duration === DURATIONS.MONTHLY ? 1 : 12) * 100) / 100
    );
  }

  const lieferandoCommission = useMemo(
    () => lieferandoCommissionConfig[tabKey],
    [tabKey, lieferandoCommissionConfig]
  );

  function toNum(value) {
    return isNaN(value) ? 0 : Number(value);
  }

  const lieferandoCommissionPercentage = toNum(lieferandoCommission) / 100;

  const totalOrders = useMemo(
    () => Math.round(sales / averageOrder),
    [sales, averageOrder]
  );

  const foodAmigosMonthlyAboFee = useMemo(() => {
    if (sales >= 2500) {
      return 240;
    }
    if (sales >= 700) {
      return 150;
    }
    return 35;
  }, [sales]);

  const foodAmigosDeliveryFees = useMemo(
    () => (tabKey === TABS.FOODAMIGOS_DRIVERS ? 5 : 0),
    [tabKey]
  );

  const foodAmigosServiceFees = useMemo(() => {
    return (
      foodAmigosMonthlyAboFee +
      (sales / averageOrder) * deliveryRatioPercentage * foodAmigosDeliveryFees
    );
  }, [sales, averageOrder, deliveryRatio, tabKey]);

  const lieferandoServiceFees = useMemo(() => {
    return (
      sales * lieferandoCommissionPercentage +
      toNum(lieferandoServiceFee) * totalOrders
    );
  }, [
    sales,
    averageOrder,
    deliveryRatio,
    tabKey,
    lieferandoCommission,
    lieferandoServiceFee,
  ]);

  const totalSavingsPerYear = useMemo(
    () => getAmountPerDuration(lieferandoServiceFees - foodAmigosServiceFees),
    [lieferandoServiceFees, foodAmigosServiceFees, duration]
  );

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " €";
  }

  const salesOptions = SALES.map((value) => ({
    label: currencyFormat(getAmountPerDuration(value)),
    value,
  })).toArray();

  function onChangeLieferandoCommission(value) {
    const newLieferandoCommissionConfig = { ...lieferandoCommissionConfig };
    newLieferandoCommissionConfig[tabKey] = value;
    setLieferandoCommissionConfig(newLieferandoCommissionConfig);
  }

  const renderInputs = () => {
    return (
      <>
        <DescriptionContainer>
          <Description>{t("description")}</Description>
        </DescriptionContainer>
        <ValueLabelContainer>
          <h6 style={{ marginBottom: 0 }}>{t("sales")}</h6>
          <Select
            options={salesOptions}
            defaultValue={sales}
            value={sales}
            onChange={setSales}
            style={{ width: 130, flexShrink: 0 }}
          />
        </ValueLabelContainer>
        <ValueLabelContainer>
          <h6>{t("commission")}</h6>
          <div>
            <IncrementalInput
              defaultValue={lieferandoCommission}
              value={lieferandoCommission}
              onChange={onChangeLieferandoCommission}
              addonAfter={"%"}
              min={0}
              max={100}
              fixed={0}
              incrementRate={1}
            />
          </div>
        </ValueLabelContainer>
        <FlexContainer>
          <h6>{t("average_order")}</h6>
          <ItemFlexContainer>
            <Slider
              defaultValue={averageOrder}
              value={averageOrder}
              onChange={setAverageOrder}
              style={{ width: "100%" }}
            />
            <InputNumber
              defaultValue={averageOrder}
              value={averageOrder}
              onChange={setAverageOrder}
              style={{ width: 100, flexShrink: 0 }}
              addonAfter={"€"}
            />
          </ItemFlexContainer>
        </FlexContainer>

        <ValueLabelContainer>
          <h6 style={{ marginBottom: 0 }}>{t("service_fee_lieferando")}</h6>
          <IncrementalInput
            defaultValue={lieferandoServiceFee}
            value={lieferandoServiceFee}
            onChange={setLieferandoServiceFee}
            addonAfter={"€"}
            // style={{ width: 100, flexShrink: 0 }}
            min={0}
            incrementRate={0.01}
          />
        </ValueLabelContainer>

        {tabKey === TABS.FOODAMIGOS_DRIVERS && (
          <FlexContainer>
            <h6>{t("pickup")}</h6>
            <ItemFlexContainer>
              <Slider
                defaultValue={100 - deliveryRatio}
                value={100 - deliveryRatio}
                onChange={(value) => setDeliveryRatio(100 - value)}
                included
                style={{ width: "100%" }}
              />
              <InputNumber
                defaultValue={100 - deliveryRatio}
                value={100 - deliveryRatio}
                onChange={(value) => setDeliveryRatio(100 - value)}
                style={{ width: 100, flexShrink: 0 }}
                addonAfter={"%"}
                min={0}
                max={100}
              />
            </ItemFlexContainer>
            <h6>{t("delivery")}</h6>
            <ItemFlexContainer>
              <Slider
                defaultValue={deliveryRatio}
                value={deliveryRatio}
                onChange={setDeliveryRatio}
                included
                style={{ width: "100%" }}
              />
              <InputNumber
                defaultValue={deliveryRatio}
                value={deliveryRatio}
                onChange={setDeliveryRatio}
                style={{ width: 100, flexShrink: 0 }}
                addonAfter={"%"}
                min={0}
                max={100}
              />
            </ItemFlexContainer>
          </FlexContainer>
        )}
      </>
    );
  };

  const items = [
    {
      label: t("tabs.own_drivers"),
      key: TABS.OWN_DRIVERS,
      children: renderInputs(),
    },
    {
      label: t("tabs.foodamigos_drivers"),
      key: TABS.FOODAMIGOS_DRIVERS,
      children: renderInputs(),
    },
  ];

  const chartData = [
    {
      label: "Fees",
      data: [
        {
          primary: "FoodAmigos",
          label: t("chart.fees"),
          secondary:
            foodAmigosServiceFees > 0
              ? getAmountPerDuration(foodAmigosServiceFees)
              : 0,
          color: "#69CE8C",
          rgb: "rgb(105, 206, 140)",
          hidden: tabKey === TABS.OWN_DRIVERS,
        },
        {
          primary: "Lieferando & Co",
          label: t("chart.fees"),
          secondary: getAmountPerDuration(lieferandoServiceFees),
          color: "#ff7f00",
          rgb: "rgb(255, 127, 0)",
        },
        {
          primary: "FoodAmigos",
          label: "Abo",
          secondary: getAmountPerDuration(foodAmigosMonthlyAboFee),
          color: "#bdffd9",
          rgb: "rgb(189, 255, 217)",
        },
      ],
    },
  ];

  const primaryAxis = useMemo(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    []
  );

  const secondaryAxes = useMemo(
    () => [
      {
        getValue: (datum) => datum.secondary,
        stacked: true,
        formatters: {
          scale: (value) => `${value} €`,
        },
      },
    ],
    []
  );

  const getReactProps = (element) => {
    for (const key in element) {
      if (key.startsWith("__reactProps$")) {
        return element[key];
      }
    }
    return null;
  };

  function findColumn(rgb) {
    const chartElement = chartRef();

    if (!chartElement) {
      return null;
    }

    const rectElement = Array.from(chartElement.querySelectorAll("rect")).find(
      (el) => el.style.fill === rgb
    );

    if (!rectElement) {
      return null;
    }

    return getReactProps(rectElement);
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      setChartLabelData(
        collect(chartData[0].data)
          .filter((item) => !item?.hidden)
          .map((datum) => ({
            position: findColumn(datum?.rgb),
            datum,
          }))
          .toArray()
      );
    }, 0);
  }, [
    tabKey,
    sales,
    deliveryRatio,
    averageOrder,
    lieferandoCommissionConfig,
    lieferandoServiceFee,
    duration,
    windowSize,
  ]);

  const renderChartLabels = useMemo(() => {
    return chartLabelData.map((item) => {
      return (
        <ChartLabelContainer
          style={{
            left: item?.position?.x + 20 + item?.position?.width / 2,
            top: item?.position?.y,
            borderColor: item?.datum?.color,
            borderWidth: 2,
          }}
        >
          <RowContainer>
            <h6
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: 14,
                marginBottom: 1,
              }}
            >
              {item?.datum.secondary} €
            </h6>
          </RowContainer>
          <p style={{ fontSize: 12, marginBottom: 0 }}>{item?.datum.label}</p>
        </ChartLabelContainer>
      );
    });
  }, [chartLabelData]);

  const renderChart = () => {
    return (
      <>
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <Chart
            options={{
              data: chartData,
              primaryAxis,
              secondaryAxes,
              getDatumStyle: (datum) => ({
                fill: datum.originalDatum.color,
              }),
              tooltip: {
                groupingMode: "single",
                render: (datum) => (
                  <ToolTipContainer>
                    <ColorContainer
                      style={{
                        backgroundColor:
                          datum?.focusedDatum?.originalDatum?.color,
                      }}
                    />
                    <p style={{ marginBottom: 0 }}>
                      {datum?.focusedDatum?.originalDatum?.label}
                    </p>
                  </ToolTipContainer>
                ),
              },
            }}
            id="chart"
          />
          {renderChartLabels}
        </div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <HeaderContainer>
        <h6 style={{ marginBottom: 2 }}>{t("total_savings")}</h6>
        <SavingTitle>{currencyFormat(totalSavingsPerYear)}</SavingTitle>
      </HeaderContainer>
    );
  };

  return (
    <ParentContainer>
      {renderHeader()}
      <Container>
        <ItemContainer>
          <ItemWrapper>
            <Select
              defaultValue={duration}
              value={duration}
              onChange={setDuration}
              options={durationOptions}
            />
            <Tabs
              defaultActiveKey={tabKey}
              activeKey={tabKey}
              onChange={setTabKey}
              size="large"
              items={items}
            />
          </ItemWrapper>
        </ItemContainer>
        <ItemContainer>
          <ItemWrapper style={{ minHeight: window.innerHeight * 0.5 }}>
            {renderChart()}
          </ItemWrapper>
        </ItemContainer>
      </Container>
    </ParentContainer>
  );
};

export default RoiCalculator;

const ParentContainer = tw.div`pb-8`;

const Container = tw.div`grid lg:grid-cols-2 gap-2 lg:p-4 p-2`;

const ItemContainer = tw.div`h-full`;

const ItemWrapper = tw.div`lg:p-4 p-2 w-full h-full`;

const HeaderContainer = tw.div`flex  flex-col justify-center items-center border-t px-4 py-2 sticky top-0 z-[100] bg-white border-b`;

const FlexContainer = tw.div`border-t py-4`;

const RowContainer = tw.div`flex`;

const DescriptionContainer = tw.div`py-2`;

const Description = tw.p`text-gray-default`;

const ItemFlexContainer = tw.div`flex items-center gap-4 w-full`;

const ValueLabelContainer = tw.div`flex lg:flex-row justify-between items-center gap-4 w-full py-4 border-t`;

const SavingTitle = tw.h1`text-primary-default mb-0 font-bold`;

const ToolTipContainer = tw.div`p-2 rounded-lg shadow bg-white flex items-center`;

const ColorContainer = tw.div`w-[20px] h-[20px] border-2 border-white mr-2 shadow rounded-full`;

const ChartLabelContainer = tw.div`px-2 py-1 shadow bg-white rounded-md flex-col items-center justify-center absolute`;
