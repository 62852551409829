export default {
  description:
    "By clicking \"Accept All Cookies\", you consent to cookies being stored on your device to improve site navigation, analyze site usage, and support our marketing efforts.",
  cookie_policy: 'Cookie Policy',
  button: {
    button_1: {
      title: "Accept All Cookies",
    },
    button_2: {
      title: "Reject",
    },
  },
};
