import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { SectionHeading } from "components/misc/Headings.js";
import {
  GlobalOutlined,
  CarOutlined,
  BarChartOutlined,
  MobileOutlined,
  ShoppingOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import { useTranslation } from "react-i18next";

const Container = tw.div`relative lg:pb-24 lg:pt-12 lg:px-24 px-6 flex flex-col justify-center items-center`;
const GridContainer = styled.div`
  ${tw`grid lg:grid-cols-3 grid-cols-1 gap-8`}
`;
const Heading = tw(
  SectionHeading
)`font-black text-center text-3xl sm:text-4xl lg:text-5xl lg:mb-12 mb-6`;
const FeatureIconContainer = tw.div`w-[50px] h-[50px] bg-[#DCFCE6] rounded-lg flex justify-center items-center mb-6`;
const FeatureHeading = tw.h5`text-black lg:text-left text-center`;
const FeatureDescription = tw.p`mt-1 text-gray-default mb-0 lg:text-left text-center`;

const Card = tw.div`flex-1 flex flex-col bg-white shadow-xl rounded-xl p-6 lg:items-start items-center`;

export default () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Features:RestaurantBenefitsBlock";
  const heading = t(TRANSLATION_PATH + ".title");

  const iconStyle = { fontSize: "24px", color: "#69CE8C" };

  const cards = [
    {
      icon: <GlobalOutlined style={iconStyle} />,
      title: t(TRANSLATION_PATH + ":items:item_1.title"),
      description: t(TRANSLATION_PATH + ":items:item_1.description"),
    },
    {
      icon: <CarOutlined style={iconStyle} />,
      title: t(TRANSLATION_PATH + ":items:item_2.title"),
      description: t(TRANSLATION_PATH + ":items:item_2.description"),
    },
    {
      icon: <BarChartOutlined style={iconStyle} />,
      title: t(TRANSLATION_PATH + ":items:item_3.title"),
      description: t(TRANSLATION_PATH + ":items:item_3.description"),
    },
    {
      icon: <MobileOutlined style={iconStyle} />,
      title: t(TRANSLATION_PATH + ":items:item_4.title"),
      description: t(TRANSLATION_PATH + ":items:item_4.description"),
    },
    {
      icon: <ShoppingOutlined style={iconStyle} />,
      title: t(TRANSLATION_PATH + ":items:item_5.title"),
      description: t(TRANSLATION_PATH + ":items:item_5.description"),
    },
    {
      icon: <ClockCircleOutlined style={iconStyle} />,
      title: t(TRANSLATION_PATH + ":items:item_6.title"),
      description: t(TRANSLATION_PATH + ":items:item_6.description"),
    },
  ];

  return (
    <Container>
      <Heading>{heading}</Heading>

      <GridContainer>
        {cards.map((card, i) => (
          <Card>
            <FeatureIconContainer>{card.icon}</FeatureIconContainer>
            <FeatureHeading>{card.title}</FeatureHeading>
            <FeatureDescription>{card?.description}</FeatureDescription>
          </Card>
        ))}
      </GridContainer>
    </Container>
  );
};
