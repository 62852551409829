export default {
  title: "Here’s ",
  title_highlighted: "how...",
  description: `“Don’t just tell your amigos about great spots, share your code and earn 🌮🍕🍩!”`,
  steps: {
    steps_1: {
      title: "Refer Amigos",
      description: "Share your personal restaurant referral link or code",
    },
    steps_2: {
      title: "Amigos use your codes",
      description:
        "They get up to 50% off on their purchase at the particular restaurant",
    },
    steps_3: {
      title: "Earn rewards",
      description: "You get discounts and free 🌮🍕🥗 at the restaurant ",
    },
  },
};
