import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png";


const Container = tw.div`relative bg-primary-default text-white -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold text-white`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`text-white border-b-2 border-transparent hocus:text-black hocus:border-black pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(
  Column
)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-white`;

const Divider = tw.div`my-16 border-b-0 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;


const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-100`;

const DownloadAppLinksContainer = tw.div`mt-8 lg:mt-8 flex flex-col items-center sm:block`;
const DownloadLink = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-black hocus:bg-black hocus:border text-white shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/">Home</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/restaurants">Restaurants</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Restaurants</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/login">Log In</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/register">Register</Link>
              </LinkListItem>
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/terms">Terms and Conditions</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/cookie-policy">Cookie Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/impressum">Impressum</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>FoodAmigos</ColumnHeading>
              <SubscribeText>
                We deliver high quality blog posts written by professionals
                weekly. And we promise no spam.
              </SubscribeText>
            </SubscribeNewsletterContainer>
            <DownloadAppLinksContainer>
              <DownloadLink href={process.env.REACT_APP_APP_STORE_URL}>
                <img src={appleIconImageSrc} alt="" />
                <span>Apple Store</span>
              </DownloadLink>
              <DownloadLink href={process.env.REACT_APP_GOOGLE_PLAY_URL}>
                <img src={googlePlayIconImageSrc} alt="" />
                <span>Google Play</span>
              </DownloadLink>
            </DownloadAppLinksContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          {/* <LogoContainer> */}
          {/* <LogoImg src={LogoImage} /> */}
          {/* <LogoText>FoodAmigos</LogoText> */}
          {/* </LogoContainer> */}
          <CopywrightNotice>
            &copy; 2024 Foodamigos GmbH. All Rights Reserved.
          </CopywrightNotice>
          {/* <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer> */}
        </ThreeColRow>
      </Content>
    </Container>
  );
};
