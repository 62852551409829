export default {
  description:
    "0% Kommission, monatlicher Festpreis, keine langfristigen Verträge",
  sales: "Umsatz",
  commission: "Lieferando & Co Kommission",
  average_order: "Durchschnittlicher Bestellwert",
  service_fee_lieferando: "Servicegebühr pro Bestellung Lieferando & Co",
  pickup: 'Abholung',
  delivery: 'Lieferung',
  total_savings: "Gesamtersparnisse",
  tabs: {
    own_drivers: "Eigene Fahrer",
    foodamigos_drivers: "Foodamigos Fahrer",
  },
  chart: {
    fees: "Gebühren",
  },
  duration: {
    monthly: "Monatlich",
    yearly: "Jährlich",
  },
};
